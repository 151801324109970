import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchBranchList } from "../features/commonSlice";
import axios from "axios";

import { Container, Row, Col, Form, Button, Card, Alert } from "react-bootstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../static/css/App.css";

library.add(fas, fab, far);
function ContactUs() {
    const dispatch = useDispatch();
    const {branchList} = useSelector((state) => state.common);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [datas, setDatas] = useState({
        "name": "",
        "email": "",
        "mobile": "",
        "location": "",
        "enquiryFrom": 0,
        "message": ""
    });
    const contactSubmit = async () => {
        if (datas.name == "") {
            setError("Name is required");
        } else if (datas.email == "") {
            setError("Email is required");
        } else if (datas.mobile == "") {
            setError("Mobile is required");
        } else if (datas.location == "") {
            setError("Location is required");
        } else if (datas.enquiryFrom == 0) {
            setError("Inquiry For is required");
        } else if (datas.message == "") {
            setError("Message is required");
        } else {
            setError("");
            setSuccess("");
            let brchUrl = process.env.REACT_APP_BASE_URL + "/contact/create";
            const response = await axios.post(brchUrl, datas);
            if (response.data.status == "SUCCESS") {
                setSuccess("Inquiry sent successfully");
                setDatas({
                    "name": "",
                    "email": "",
                    "mobile": "",
                    "location": "",
                    "enquiryFrom": 0,
                    "message": ""
                });
            } else {
                setError(response.data.message);
            }
        }

    }

    const handleMessageValidation = (htstring) => {
        var stripped = htstring.replace(/(<([^>]+)>)/ig, "");
        setDatas({...datas, message: stripped});
    }

    const handleString = (e) => {
        const value = e.target.value;
        let names=e.target.name;
        const regex = /^[0-9a-zA-Z(\- )]+$/; //this will admit letters, numbers and dashes
        if (value.match(regex) || value === "") {
            setDatas({...datas, [names]: value});
        }
    }

    useEffect(() => {
        dispatch(fetchBranchList());
        window.scrollTo(0, 0);
        setError("");
    }, [dispatch]);

    useEffect(() => {
        console.log(datas);
        setError("");
    }, [datas]);
    return (
            <div>
                <Container fluid="xl" className="px-md-5 px-3 px-xl-3">
                    <Row>
                        <h2>Contact Us</h2>
                    </Row>
                    <Row>
                        {error && <Alert variant="danger" className="text-center">                        
                            <p className="mb-0">
                                {error}
                            </p>
                        </Alert>} 
                        {success && <Alert variant="success" className="text-center">                        
                            <p className="mb-0">
                                {success}
                            </p>
                        </Alert>} 
                    </Row>
                    <Row>
                        <Form className="">
                            <Form.Group
                                as={Row}
                                className="mb-3 justify-content-center"
                                controlId="formHorizontalEmail"
                                >
                                <Form.Label column sm={1}>
                                    <b>Name</b>
                                </Form.Label>
                                <Col sm={4}>
                                <Form.Control type="text" name="name" placeholder="Name" onChange={(e) => handleString(e)} value={datas.name}/>
                                </Col>
                            </Form.Group>
                            <Form.Group
                                as={Row}
                                className="mb-3 justify-content-center"
                                controlId="formHorizontalEmail"
                                >
                                <Form.Label column sm={1}>
                                    <b>Email</b>
                                </Form.Label>
                                <Col sm={4}>
                                <Form.Control type="email" name="email" placeholder="Email address"  onChange={(e) => setDatas({...datas, email: e.target.value})} value={datas.email}/>
                                </Col>
                            </Form.Group>
                            <Form.Group
                                as={Row}
                                className="mb-3 justify-content-center"
                                controlId="formHorizontalEmail"
                                >
                                <Form.Label column sm={1}>
                                    <b>Mobile No</b>
                                </Form.Label>
                                <Col sm={4}>
                                <Form.Control type="number" size="sm"  name="mobile" className="w-100 text-start" placeholder="Mobile No"  onChange={(e) => setDatas({...datas, mobile: e.target.value})} value={datas.mobile}/>
                                </Col>
                            </Form.Group>
                            <Form.Group
                                as={Row}
                                className="mb-3 justify-content-center"
                                controlId="formHorizontalEmail"
                                >
                                <Form.Label column sm={1}>
                                    <b>Location</b>
                                </Form.Label>
                                <Col sm={4}>
                                <Form.Control type="text" placeholder="Location" name="location"  onChange={(e) => handleString(e)} value={datas.location}/>
                                </Col>
                            </Form.Group>
                            <Form.Group
                                as={Row}
                                className="mb-3 justify-content-center"
                                controlId="formHorizontalEmail"
                                >
                                <Form.Label column sm={1}>
                                    <b>Inquiry For</b>
                                </Form.Label>
                                <Col sm={4}>
                                <Form.Select aria-label="Default select example"  onChange={(e) => setDatas({...datas, enquiryFrom: e.target.value})} value={datas.enquiryFrom}>
                                    <option value="1">Select Type</option>
                                    <option value="1">General Inquiry</option>
                                    <option value="2">Product Inquiry</option>
                                    <option value="3">Complaints</option>
                                </Form.Select>
                                </Col>
                            </Form.Group>
                            <Form.Group
                                as={Row}
                                className="mb-3 justify-content-center"
                                controlId="formHorizontalEmail"
                                >
                                <Form.Label column sm={1}>
                                    <b>Message</b>
                                </Form.Label>
                                <Col sm={4}>
                                <Form.Control
                                    as="textarea"
                                    rows={5}
                                    type="text"
                                    placeholder="Your Query"
                                    onChange={(e) => handleMessageValidation(e.target.value)}  
                                    value={datas.message}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Col sm={{span: 6, offset: 6}}>
                                <Button type="button" onClick={contactSubmit}>Submit</Button>
                                </Col>
                            </Form.Group>
                        </Form>
                    </Row>
                    <Row>
                        <h2>Our Branches</h2>
                    </Row>
                    <Row>
                        {branchList &&
                                    branchList.map((bl, index) => (
                                        <Col sm="3" className="my-3">
                                        <Card className="h-100">
                                        <Card.Body className="p-2 lh-lg text-wrap">
                                            <p className="text-primary mb-0">
                                                <strong>
                                                    {index + 1}. {bl.branchName}
                                                </strong>
                                            </p>
                                            {bl.address}
                                            <br />
                                            {bl.areaName},{bl.city}-{bl.pincode}
                                            <br />
                                            <strong>Mobile No:</strong> {bl.mobile}
                                            <br />
                                            <strong>Phone No:</strong>
                                            {bl.phone},{bl.alternativePhone}
                                            <br />
                                        </Card.Body>
                                        </Card>
                                        </Col>
                                                ))}
                    </Row>
                </Container>
            </div>
            );
}

export default ContactUs;
