import { useDispatch, useSelector } from "react-redux";
import {
  fetchOffersProduct,
  changeStateTrue,
  changeStateFalse,
  changeLoading,
  fetchOfferFilters,
  clearProduct,
} from "../features/productSlice";
import { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import META from "../common/meta";
import ProductCard from "../components/product/product";
import CatlogPriceFilter from "../components/priceSlider/price-slider";
import { Container, Row, Col, Dropdown, Form } from "react-bootstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../static/css/App.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
library.add(fas, fab, far);
function Offers() {
  const { slug } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [priceFilter, setPriceFilter] = useState(null);
  const [brandFilter, setBrandFilter] = useState([]);
  const [brandIdFilter, setBrandIdFilter] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [sorting, setSorting] = useState(null);
  const [order, setOrder] = useState(null);
  //                const[categoryId, setCategoryId] = useState(null);
  const dispatch = useDispatch();
  const {
    productList,
    error,
    response,
    completed,
    filterCategories,
    filterBrands,
    minPrice,
    maxPrice,
  } = useSelector((state) => state.product);
  const fetchData = () => {
    setIsLoading(true);
    //        dispatch(fetchOffersProduct());
    dispatch(
      fetchOffersProduct({
        categoryId: categoryFilter.join(","),
        price: priceFilter,
        brand: brandFilter.join(","),
        brandId: brandIdFilter.join(","),
        order: order,
        sort: sorting,
      })
    );
    dispatch(changeStateTrue());
  };
  useEffect(() => {
    dispatch(changeLoading());
    if (searchParams.size > 0) {
      if (searchParams.get("brand")) {
        dispatch(fetchOfferFilters({ brand: searchParams.get("brand") }));
        setBrandFilter([searchParams.get("brand")]);
      }
      if (searchParams.get("category")) {
        dispatch(
          fetchOfferFilters({ categoryId: searchParams.get("category") })
        );
        setCategoryFilter([searchParams.get("category")]);
      }
    } else {
      dispatch(
        fetchOfferFilters({
          brand: brandFilter.join(","),
          categoryId: categoryFilter.join(","),
        })
      );
      fetchData();
    }
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } =
        document.documentElement;
      if (scrollTop + clientHeight >= scrollHeight - 20 && !isLoading) {
        fetchData();
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [slug, isLoading]);
  useEffect(() => {
    if (completed) {
      setIsLoading(false);
    }
  }, [completed]);

  useEffect(() => {
    if (priceFilter) {
      dispatch(clearProduct());
      fetchData();
    }
  }, [priceFilter]);
  useEffect(() => {
    if (brandFilter.length > 0) {
      dispatch(clearProduct());

      fetchData();
    }
  }, [brandFilter]);
  useEffect(() => {
    if (brandIdFilter.length > 0) {
      dispatch(clearProduct());
      fetchData();
    }
  }, [brandIdFilter]);
  useEffect(() => {
    if (categoryFilter.length > 0) {
      dispatch(clearProduct());

      fetchData();
    }
  }, [categoryFilter]);

  const handleBrandFilter = (e) => {
    let brandId = e.target.value;
    if (brandIdFilter.indexOf(brandId) >= 0) {
      setBrandIdFilter(brandIdFilter.filter((bf) => bf !== brandId));
    } else {
      setBrandIdFilter([...brandIdFilter, brandId]);
    }
  };
  const handleCategoryFilter = (e) => {
    let brandId = e.target.value;
    if (brandId == "all") {
      dispatch(
        fetchOfferFilters({ brand: brandFilter.join(","), categoryId: null })
      );
    }
    //        if (categoryFilter.indexOf(brandId) >= 0) {
    //            setCategoryFilter(categoryFilter.filter((bf) => bf !== brandId));
    //        } else {
    setCategoryFilter([brandId]);
    //        }
  };
  const handleSortingFilter = (e) => {
    let value = e.target.value;
    setSorting("price");
    if (value == 1) {
      setOrder("low");
    } else if (value == 2) {
      setOrder("high");
    } else if (value == 4) {
      setSorting("name");
      setOrder("asc");
    } else if (value == 5) {
      setSorting("name");
      setOrder("desc");
    } else {
      setOrder("default");
    }
  };
  useEffect(() => {
    if (order) {
      dispatch(clearProduct());
      fetchData();
    }
  }, [order]);
  return (
    <div>
      <Container fluid="xl"  className="px-md-5 px-3 px-xl-3">
        <Row className="my-4">
          <Col md="3">
            <h1 className="fs-4">Offers</h1>
          </Col>
          <Col md="4">
            <label className="float-start" style={{ paddingLeft: "5%" }}>
              Price Range
            </label>
            {minPrice && maxPrice && (
              <CatlogPriceFilter
                min={minPrice}
                max={maxPrice}
                setPriceFilter={setPriceFilter}
              />
            )}
          </Col>
          <Col md="5" className="text-end">
            <Dropdown className="d-inline mx-2" autoClose="outside">
              <Dropdown.Toggle
                className="product-filters text-white"
                id="dropdown-autoclose-outside"
              >
                Brands
              </Dropdown.Toggle>

              <Dropdown.Menu className="filter-brand-section">
                {filterBrands &&
                  filterBrands.length > 0 &&
                  filterBrands.map((fb, index) => (
                    <Dropdown.Item as="button" key={index}>
                      <Form.Check
                        type="checkbox"
                        id={`brand-checkbox-${index}`}
                        label={fb.brandName}
                        value={fb.brandID}
                        name="brand"
                        onChange={handleBrandFilter}
                      />
                    </Dropdown.Item>
                  ))}
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown className="d-inline mx-2" autoClose="outside">
              <Dropdown.Toggle
                className="product-filters text-white"
                id="dropdown-autoclose-outside"
              >
                Categories
              </Dropdown.Toggle>

              <Dropdown.Menu className="filter-category-section">
                <Dropdown.Item as="button" key="0">
                  <Form.Check
                    type="radio"
                    id="category-checkbox"
                    label="All"
                    value="all"
                    name="category"
                    onChange={handleCategoryFilter}
                  />
                </Dropdown.Item>
                {filterCategories &&
                  filterCategories.length > 0 &&
                  filterCategories.map((fb, index) => (
                    <Dropdown.Item as="button" key={index}>
                      <Form.Check
                        type="radio"
                        id={`category-checkbox-${index}`}
                        label={fb.categoryName}
                        value={fb.slug}
                        name="category"
                        onChange={handleCategoryFilter}
                      />
                    </Dropdown.Item>
                  ))}
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown className="d-inline mx-2" autoClose="true">
              <Dropdown.Toggle
                className="product-filters  text-white"
                id="dropdown-autoclose-inside"
              >
                Default Sorting
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item as="button" key="sort-0">
                  <Form.Check
                    type="radio"
                    id="sorting-checkbox-0"
                    label="Default Sorting"
                    value="0"
                    name="sorting"
                    onChange={handleSortingFilter}
                  />
                </Dropdown.Item>
                <Dropdown.Item as="button" key="sort-1">
                  <Form.Check
                    type="radio"
                    id="sorting-checkbox-1"
                    label="Price Low"
                    value="1"
                    name="sorting"
                    onChange={handleSortingFilter}
                  />
                </Dropdown.Item>
                <Dropdown.Item as="button" key="sort-2">
                  <Form.Check
                    type="radio"
                    id="sorting-checkbox-2"
                    label="Price High"
                    value="2"
                    name="sorting"
                    onChange={handleSortingFilter}
                  />
                </Dropdown.Item>
                <Dropdown.Item as="button" key="sort-4">
                  <Form.Check
                    type="radio"
                    id="sorting-checkbox-4"
                    label="Name Asc"
                    value="4"
                    name="sorting"
                    onChange={handleSortingFilter}
                  />
                </Dropdown.Item>
                <Dropdown.Item as="button" key="sort-5">
                  <Form.Check
                    type="radio"
                    id="sorting-checkbox-5"
                    label="Name Desc"
                    value="5"
                    name="sorting"
                    onChange={handleSortingFilter}
                  />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </Container>

      <Container fluid="xl"  className="px-md-5 px-3 px-xl-3">
        <Row className="my-5">
          {productList &&
            productList.map((product, index) =>
              product.variations.length > 0 ? (
                <ProductCard product={product} index={index} />
              ) : (
                ""
              )
            )}
        </Row>
      </Container>
    </div>
  );
}

export default Offers;
{
  /* 
 <Container fluid className="previous-purchase py-4"> 
 
 <Container>
 <Row>
 <h2 className="fs-4 py-3 text-white">Your previous purchase in this category</h2>
 <OwlCarousel className='owl-theme' autoplay margin={10} items={4} dots={false}>
 {productList && productList.map((product, index) => (
 product.variations.length > 0 && index <=10 ?
 <ProductCard product={product} type="owl"/>:""
 ))}                                                                 
 </OwlCarousel> 
 </Row>
 </Container> 
 
 </Container>
 */
}

{
  /*  <Row>
 <Col lg="7">
 <Image src=
 {categoryList} fluid style={{height: "346px"}}/>
 </Col>
 <Col lg="5">
 <Image src={categoryList1} fluid  className="my-2"/>
 <Image src={categoryList2} fluid  className="my-2"/>
 </Col>
 </Row>
 * */
}
