import { useDispatch, useSelector } from "react-redux";
import {
fetchBrandProduct,
        changeStateTrue,
        changeStateFalse,
        changeLoading,
        fetchBrandFilters,
        clearProduct,
        } from "../features/productSlice";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ProductCard from "../components/product/product";
import CatlogPriceFilter from "../components/priceSlider/price-slider";
import dairyProducts from "../static/images/dairy_products.png";
import {
Container,
        Row,
        Col,
        Form,
        Breadcrumb,
        Dropdown,
        } from "react-bootstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../static/css/App.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import META from '../common/meta';
library.add(fas, fab, far);
function BrandProduct() {
    const {slug} = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [priceFilter, setPriceFilter] = useState(null);
    const [maxPriceFilter, setMaxPriceFilter] = useState(null);
    const [brandSlug, setBrandSlug] = useState(null);
    const [sorting, setSorting] = useState(null);
    const [order, setOrder] = useState(null);
    const [offer, setOffer] = useState(null);
    //                const[categoryId, setCategoryId] = useState(null);
    const dispatch = useDispatch();
    const {
        brandProductList,
        error,
        response,
        completed,
        breadCrumb,
        filterCategories,
        filterBrands,
        minPrice,
        maxPrice,
    } = useSelector((state) => state.product);
    const fetchData = () => {
        setIsLoading(true);
        if (brandSlug) {
            dispatch(
                    fetchBrandProduct({
                        brand: brandSlug,
                        price: priceFilter,
                        order: order,
                        sort: sorting,
                        offer: offer,
                    })
                    );
        }
    };
    useEffect(() => {
        setBrandSlug(slug);
        dispatch(fetchBrandFilters({brand: slug}));
        window.scrollTo(0, 0);
    }, [slug]);
    useEffect(() => {
        dispatch(changeLoading());
        fetchData();
        dispatch(changeStateTrue());
    }, [brandSlug]);
    useEffect(() => {
        const handleScroll = () => {
            const {scrollTop, clientHeight, scrollHeight} =
                    document.documentElement;
            if (scrollTop + clientHeight >= scrollHeight - 20 && !isLoading) {
                fetchData();
                dispatch(changeStateTrue());
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [slug, isLoading]);
    useEffect(() => {
        if (completed) {
            setIsLoading(false);
        }
    }, [completed]);
    useEffect(() => {
        dispatch(clearProduct());
        fetchData();
        dispatch(changeStateTrue());
    }, [priceFilter]);
    const handleSortingFilter = (e) => {
        let value = e.target.value;
        setSorting("price");
        setOffer(null);
        if (value == 1) {
            setOrder("low");
        } else if (value == 2) {
            setOrder("high");
        } else if (value == 3) {
            setOrder("default");
            setOffer(1);
        } else if (value == 4) {
            setSorting("name");
            setOrder("asc");
        } else if (value == 5) {
            setSorting("name");
            setOrder("desc");
        } else {
            setOrder("default");
        }
    };
    useEffect(() => {
        if (order) {
            dispatch(clearProduct());
            fetchData();
            dispatch(changeStateTrue());
        }
    }, [order]);
    return (
            <div>
                <META slug={brandSlug} type="brands"/>
                <Container fluid="xl" className="px-md-5 px-3 px-xl-3">
                    <Row>
                        {breadCrumb != null && (
                                    <Breadcrumb>
                                        <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                                        {breadCrumb.brands && (
                                                                        <Breadcrumb.Item active>
                                                                            {breadCrumb.brands.brandName}
                                                                        </Breadcrumb.Item>
                                                                        )}
                                    </Breadcrumb>
                                            )}
                    </Row>
                    <Row>
                        <img src={dairyProducts} alt="Category banner" />
                    </Row>
                    <Row className="my-4">
                        <Col md="3">
                        <h1 className="fs-4">
                            {filterBrands.length > 0 && filterBrands[0].brandName}{" "}
                            <span className="text-secondary" style={{fontSize: "12px"}}>
                                (34 Products)
                            </span>
                        </h1>
                        </Col>
                        <Col md="4">
                        <label className="float-start" style={{paddingLeft: "5%"}}>
                            Price Range
                        </label>
                        {minPrice && maxPrice && (
                            <CatlogPriceFilter
                                min={minPrice}
                                max={maxPrice}
                                setPriceFilter={setPriceFilter}
                                />
                                    )}
                        </Col>
                        <Col md="5" className="text-end">
                        <Dropdown className="d-inline mx-2" autoClose="inside">
                            <Dropdown.Toggle
                                className="product-filters  text-white"
                                id="dropdown-autoclose-inside"
                                >
                                Default Sorting
                            </Dropdown.Toggle>
            
                            <Dropdown.Menu>
                                <Dropdown.Item as="button" key="sort-0">
                                    <Form.Check
                                        type="radio"
                                        id="sorting-checkbox-0"
                                        label="Default Sorting"
                                        value="0"
                                        name="sorting"
                                        onChange={handleSortingFilter}
                                        />
                                </Dropdown.Item>
                                <Dropdown.Item as="button" key="sort-1">
                                    <Form.Check
                                        type="radio"
                                        id="sorting-checkbox-1"
                                        label="Price Low"
                                        value="1"
                                        name="sorting"
                                        onChange={handleSortingFilter}
                                        />
                                </Dropdown.Item>
                                <Dropdown.Item as="button" key="sort-2">
                                    <Form.Check
                                        type="radio"
                                        id="sorting-checkbox-2"
                                        label="Price High"
                                        value="2"
                                        name="sorting"
                                        onChange={handleSortingFilter}
                                        />
                                </Dropdown.Item>
                                <Dropdown.Item as="button" key="sort-3">
                                    <Form.Check
                                        type="radio"
                                        id="sorting-checkbox-3"
                                        label="Offers Only"
                                        value="3"
                                        name="sorting"
                                        onChange={handleSortingFilter}
                                        />
                                </Dropdown.Item>
                                <Dropdown.Item as="button" key="sort-4">
                                    <Form.Check
                                        type="radio"
                                        id="sorting-checkbox-4"
                                        label="Name Asc"
                                        value="4"
                                        name="sorting"
                                        onChange={handleSortingFilter}
                                        />
                                </Dropdown.Item>
                                <Dropdown.Item as="button" key="sort-5">
                                    <Form.Check
                                        type="radio"
                                        id="sorting-checkbox-5"
                                        label="Name Desc"
                                        value="5"
                                        name="sorting"
                                        onChange={handleSortingFilter}
                                        />
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        </Col>
                    </Row>
                </Container>
            
                <Container fluid="xl" className="px-md-5 px-3 px-xl-3">
                    <Row className="my-5">
                        {brandProductList &&
                                    brandProductList.map((product, index) =>
                                        product.variations.length > 0 ? (
                                        <ProductCard product={product} index={index} />
                                                ) : (
                                                ""
                                                )
                                    )}
                    </Row>
                </Container>
            </div>
            );
}

export default BrandProduct;

{
    /*<!--<Dropdown className="d-inline mx-2" autoClose="outside">
     <Dropdown.Toggle className="product-filters  text-white" id="dropdown-autoclose-outside">
     Categories
     </Dropdown.Toggle>
     
     <Dropdown.Menu>
     {filterCategories && filterCategories.length > 0 && filterCategories.map((fc, index) => (
     <Dropdown.Item as="button" key={index}>
     <Form.Check
     type="checkbox"
     id={`d-procheckbox-${index}`}
     label={fc.categoryName}
     value={fc.categoryID}
     />
     </Dropdown.Item>
     ))}
     </Dropdown.Menu>
     </Dropdown>*/
}
