import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const productState = {
    //    updateState: false,
    //    loading: false,
    productList: [],
    singleProductDetails: "",
    offset: 0,
    error: "",
    response: "",
    completed: false,
    monthlyListSuccess: false,
    monthlyListCartSuccess: false,
    deleteSuccess: false,
    monthlyListProduct: [],
    recentPurchaseListProduct: [],
    searchProductList: [],
    brandProductList: [],
    productGroupList: [],
    breadCrumb: null,
    filterCategories: [],
    filterBrands: [],
    minPrice: 0,
    maxPrice: 0,
};
export const fetchProduct = createAsyncThunk(
        "product/fetchProduct",
        async (data, { getState }) => {
    const product = getState().product;
    const user = getState().auth.user;
    var filters = "";
    if (data.price) {
        filters = `&price=${data.price}`;
    }
    if (data.brand) {
        filters += `&brandId=${data.brand}`;
    }
    if (data.order && data.sort && data.order != "default") {
        filters += `&orderby=${data.order}&sortby=${data.sort}`;
    }
    if (data.offer) {
        filters += `&offer=1`;
    }
    const branch = JSON.parse(localStorage.getItem("selectedBranch"));
    const response = await axios.get(
            process.env.REACT_APP_PRODUCT_BASE_URL +
            `/products/get?limit=20&offset=${product.offset}&categoryId=${data.categoryId}&branchId=${branch.id}${filters}`,
            {headers: {tempusername: user.name}}
    );
    return response.data;
}
);
export const fetchBrandProduct = createAsyncThunk(
        "product/fetchBrandProduct",
        async (data, { getState }) => {
    const product = getState().product;
    const user = getState().auth.user;
    var filters = "";
    if (data.price) {
        filters += `&price=${data.price}`;
    }
    if (data.brand) {
        filters += `&brandId=${data.brand}`;
    }
    if (data.order && data.sort && data.order != "default") {
        filters += `&orderby=${data.order}&sortby=${data.sort}`;
    }
    if (data.offer) {
        filters += `&offer=1`;
    }
    const branch = JSON.parse(localStorage.getItem("selectedBranch"));
    const response = await axios.get(
            process.env.REACT_APP_PRODUCT_BASE_URL +
            `/products/get?limit=20&offset=${product.offset}&brand=${data.brand}&branchId=${branch.id}${filters}`,
            {headers: {tempusername: user.name}}
    );
    return response.data;
}
);
export const fetchProductGroup = createAsyncThunk(
        "product/fetchProductGroup",
        async (data, { getState }) => {
    const product = getState().product;
    const user = getState().auth.user;
    var filters = "";
    if (data.price) {
        filters = `&price=${data.price}`;
    }
    if (data.brand) {
        filters += `&brandId=${data.brand}`;
    }
    if (data.order && data.sort && data.order != "default") {
        filters += `&orderby=${data.order}&sortby=${data.sort}`;
    }
    if (data.offer) {
        filters += `&offer=1`;
    }

    const branch = JSON.parse(localStorage.getItem("selectedBranch"));
    const response = await axios.get(
            process.env.REACT_APP_PRODUCT_BASE_URL +
            `/products/get?limit=20&offset=${product.offset}&group=${data.group}&branchId=${branch.id}${filters}`,
            {headers: {tempusername: user.name}}
    );
    return response.data;
}
);
export const fetchProductDetails = createAsyncThunk(
        "product/fetchProductDetails",
        async (data, { getState }) => {
    const token = getState().auth.token;
    const branch = JSON.parse(localStorage.getItem("selectedBranch"));
    if (token) {
        var response = await axios.get(
                process.env.REACT_APP_PRODUCT_BASE_URL +
                `/product/details?id=${data}&branchId=${branch.id}`,
                {headers: {Authorization: token}}
        );
    } else {
        response = await axios.get(
                process.env.REACT_APP_PRODUCT_BASE_URL +
                `/product/details?id=${data}&branchId=${branch.id}`
                );
    }

    return response.data;
}
);

export const fetchMontlyList = createAsyncThunk(
        "product/fetchMonthlyList",
        async (data, { getState }) => {
    const token = getState().auth.token;
    const branch = JSON.parse(localStorage.getItem("selectedBranch"));
    const response = await axios.post(
            process.env.REACT_APP_CUSTOMER_BASE_URL + `/customer/monthlist/get`,
            {branchId: branch.id, offset: 0, limit: 20},
            {headers: {Authorization: token}}
    );
    return response.data;
}
);

export const addMonthlyList = createAsyncThunk(
        "product/addMonthlyList",
        async (data, { getState }) => {
    const token = getState().auth.token;
    const response = await axios.post(
            process.env.REACT_APP_CUSTOMER_BASE_URL + `/customer/monthlist/add`,
            data,
            {headers: {Authorization: token}}
    );
    return response.data;
}
);

export const deleteMontlyList = createAsyncThunk(
        "product/deleteMontlyList",
        async (data, { getState }) => {
    const token = getState().auth.token;
    const response = await axios.post(
            process.env.REACT_APP_CUSTOMER_BASE_URL + `/customer/monthlist/delete`,
            data,
            {headers: {Authorization: token}}
    );
    return response.data;
}
);

export const addMontlyListCart = createAsyncThunk(
        "product/addMontlyListCart",
        async (data, { getState }) => {
    const token = getState().auth.token;
    const user = getState().auth.user;
    const response = await axios.post(
            process.env.REACT_APP_CUSTOMER_BASE_URL + `/customer/monthlist/addcart`,
            {
                tempID: user.name,
                wishlistIds: data,
            },
            {headers: {Authorization: token}}
    );
    return response.data;
}
);

export const fetchSearchProduct = createAsyncThunk(
        "product/fetchSearchProduct",
        async (data, { getState }) => {
    const product = getState().product;
    const user = getState().auth.user;
    const branch = JSON.parse(localStorage.getItem("selectedBranch"));
    const response = await axios.get(
            process.env.REACT_APP_PRODUCT_BASE_URL +
            `/product/search?limit=10&offset=0&keyword=${data}&branchId=${branch.id}`,
            {headers: {tempusername: user.name}}
    );
    return response.data;
}
);
export const fetchOffersProduct = createAsyncThunk(
        "product/fetchOffersProduct",
        async (data, { getState }) => {
    const product = getState().product;
    const user = getState().auth.user;
    var filters = "";
    if (data.price) {
        filters = `&price=${data.price}`;
    }
    if (data.brand) {
        filters += `&brand=${data.brand}`;
    }
    if (data.brandId) {
        filters += `&brandId=${data.brandId}`;
    }
    if (data.categoryId && data.categoryId != "all") {
        filters += `&categoryId=${data.categoryId}`;
    }

    if (data.order && data.sort && data.order != "default") {
        filters += `&orderby=${data.order}&sortby=${data.sort}`;
    }
    const branch = JSON.parse(localStorage.getItem("selectedBranch"));
    const response = await axios.get(
            process.env.REACT_APP_PRODUCT_BASE_URL +
            `/products/get?limit=20&offset=${product.offset}&branchId=${branch.id}&offer=1${filters}`,
            {headers: {tempusername: user.name}}
    );
    return response.data;
}
);
export const fetchProductFilters = createAsyncThunk(
        "product/fetchProductFilters",
        async (data, { getState }) => {
    const product = getState().product;
    const user = getState().auth.user;
    const branch = JSON.parse(localStorage.getItem("selectedBranch"));
    const response = await axios.get(
            process.env.REACT_APP_PRODUCT_BASE_URL +
            `/products/get/filters?categoryId=${data.categoryId}&branchId=${branch.id}`
            );
    return response.data;
}
);
export const fetchBrandFilters = createAsyncThunk(
        "product/fetchBrandFilters",
        async (data, { getState }) => {
    const product = getState().product;
    const user = getState().auth.user;
    const branch = JSON.parse(localStorage.getItem("selectedBranch"));
    const response = await axios.get(
            process.env.REACT_APP_PRODUCT_BASE_URL +
            `/products/get/filters?brand=${data.brand}&branchId=${branch.id}`
            );
    return response.data;
}
);
export const fetchProductGroupFilters = createAsyncThunk(
        "product/fetchProductGroupFilters",
        async (data, { getState }) => {
    const product = getState().product;
    const user = getState().auth.user;
    const branch = JSON.parse(localStorage.getItem("selectedBranch"));
    const response = await axios.get(
            process.env.REACT_APP_PRODUCT_BASE_URL +
            `/products/get/filters?group=${data.group}&branchId=${branch.id}`
            );
    return response.data;
}
);

export const fetchRecentPurchaseList = createAsyncThunk(
        "orders/recentPurchase",
        async (data, { getState }) => {
    const token = getState().auth.token;
    const lastselectedBranch = JSON.parse(
            localStorage.getItem("selectedBranch")
            );
    const branchId = getState().branchId || lastselectedBranch.id;
    const response = await axios.post(
            process.env.REACT_APP_ORDER_BASE_URL + `/orders/recent/products`,
            {branchId: branchId, offset: 0, limit: 20},
            {headers: {Authorization: token}}
    );
    return response.data;
}
);

export const fetchOfferFilters = createAsyncThunk(
        "product/fetchOfferFilters",
        async (data, { getState }) => {
    const product = getState().product;
    const user = getState().auth.user;
    var filters = "";
    if (data.brand) {
        filters = `&brand=${data.brand}`;
    }
    if (data.categoryId && data.categoryId != "all") {
        filters = `&categoryId=${data.categoryId}`;
    }
    const branch = JSON.parse(localStorage.getItem("selectedBranch"));
    const response = await axios.get(
            process.env.REACT_APP_PRODUCT_BASE_URL +
            `/products/get/filters?offer=1&branchId=${branch.id}${filters}`
            );
    return response.data;
}
);

export const addProductReview = createAsyncThunk(
        "product/addProductReview",
        async (data, { getState }) => {
    const token = getState().auth.token;
    const response = await axios.post(process.env.REACT_APP_CUSTOMER_BASE_URL + "/customer/review/product", data, {headers: {Authorization: token}});
    console.log(response);
    return response.data;
}
);

const productSlice = createSlice({
    name: "product",
    initialState: productState,
    reducers: {
        changeStateTrue: (state) => {
            state.completed = false;
            state.offset = state.offset + 20;
        },
        changeStateFalse: (state) => {
            state.updateState = false;
        },
        clearResponse: (state) => {
            state.response = "";
        },
        changeLoading: (state) => {
            state.singleProductDetails = "";
            state.monthlyListSuccess = false;
            state.monthlyListCartSuccess = false;
            state.deleteSuccess = false;
            state.monthlyListProduct = [];
            state.recentPurchaseListProduct = [];
            state.searchProductList = [];
            state.productList = [];
            state.productGroupList = [];
            state.brandProductList = [];
            state.offset = 0;
            state.completed = false;
            state.breadCrumb = null;
            state.filterCategories = [];
            state.filterBrands = [];
            state.minPrice = 0;
            state.maxPrice = 0;
        },
        clearSearchProduct: (state) => {
            state.searchProductList = [];
        },
        clearProduct: (state) => {
            state.productList = [];
            state.brandProductList = [];
            state.offset = 0;
            state.completed = false;
        },
    },
    extraReducers: (builder) => {
        //        builder
        //                .addCase(addEmployee.pending, (state) => {
        //                    state.loading = true;
        //                })
        //                .addCase(addEmployee.fulfilled, (state, action) => {
        //                    state.loading = false;
        //                    state.employeeList.push(action.payload);
        //                    state.response = "add";
        //                })
        //                .addCase(addEmployee.rejected, (state, action) => {
        //                    state.loading = false;
        //                    state.error = action.error.message;
        //                });

        builder
                .addCase(fetchProduct.fulfilled, (state, action) => {
                    //                    console.log(action);
                    if (action.payload.status == "SUCCESS") {
                        state.productList = [...state.productList, ...action.payload.data];
                        state.completed = true;
                    }
                })
                .addCase(fetchProduct.rejected, (state, action) => {
                    state.error = action.error.message;
                })
                .addCase(fetchBrandProduct.fulfilled, (state, action) => {
                    //                    console.log(action);
                    if (action.payload.status == "SUCCESS") {
                        state.brandProductList = [
                            ...state.brandProductList,
                            ...action.payload.data,
                        ];
                        state.completed = true;
                    }
                })
                .addCase(fetchBrandProduct.rejected, (state, action) => {
                    state.error = action.error.message;
                })

                .addCase(fetchProductDetails.fulfilled, (state, action) => {
                    //                    console.log(action);
                    state.singleProductDetails = action.payload.data;
                })
                .addCase(fetchProductDetails.rejected, (state, action) => {
                    state.error = action.error.message;
                })

                .addCase(fetchRecentPurchaseList.fulfilled, (state, action) => {
                    //                    console.log(action);
                    state.recentPurchaseListProduct = action.payload.data;
                })
                .addCase(fetchRecentPurchaseList.rejected, (state, action) => {
                    state.error = action.error.message;
                })
                .addCase(addMonthlyList.fulfilled, (state, action) => {
                    if (action.payload.status === "SUCCESS") {
                        state.monthlyListSuccess = true;
                    }
                })
                .addCase(addMonthlyList.rejected, (state, action) => {
                    state.error = action.error.message;
                })

                .addCase(fetchMontlyList.fulfilled, (state, action) => {
                    if (action.payload.status === "SUCCESS") {
                        state.monthlyListProduct = action.payload.data.result;
                    }
                })
                .addCase(fetchMontlyList.rejected, (state, action) => {
                    state.error = action.error.message;
                })

                .addCase(deleteMontlyList.fulfilled, (state, action) => {
                    if (action.payload.status == "SUCCESS") {
                        state.deleteSuccess = true;
                    }
                })
                .addCase(deleteMontlyList.rejected, (state, action) => {
                    state.error = action.error.message;
                })
                .addCase(fetchSearchProduct.fulfilled, (state, action) => {
                    if (action.payload.status == "SUCCESS") {
                        state.searchProductList = action.payload.data;
                    } else {
                        state.searchProductList = [];
                    }
                })
                .addCase(fetchSearchProduct.rejected, (state, action) => {
                    state.error = action.error.message;
                })
                .addCase(fetchOffersProduct.fulfilled, (state, action) => {
                    if (action.payload.status == "SUCCESS") {
                        state.productList = [...state.productList, ...action.payload.data];
                        state.completed = true;
                    } else {
                        state.searchProductList = [];
                    }
                })
                .addCase(fetchOffersProduct.rejected, (state, action) => {
                    state.error = action.error.message;
                })
                .addCase(fetchOfferFilters.fulfilled, (state, action) => {
                    if (action.payload.status == "SUCCESS") {
                        state.filterCategories = action.payload.data.categories;
                        state.filterBrands = action.payload.data.brands;
                        state.minPrice = action.payload.data.minPrice;
                        state.maxPrice = action.payload.data.maxPrice;
                    }
                })
                .addCase(fetchOfferFilters.rejected, (state, action) => {
                    state.error = action.error.message;
                })
                .addCase(fetchProductFilters.fulfilled, (state, action) => {
                    if (action.payload.status == "SUCCESS") {
                        state.breadCrumb = action.payload.data.breadCrumbs;
                        state.filterCategories = action.payload.data.categories;
                        state.bannerImage = action.payload.data.bannerImage;
                        state.filterBrands = action.payload.data.brands;
                        state.minPrice = action.payload.data.minPrice;
                        state.maxPrice = action.payload.data.maxPrice;
                    }
                })
                .addCase(fetchProductFilters.rejected, (state, action) => {
                    state.error = action.error.message;
                })
                .addCase(fetchBrandFilters.fulfilled, (state, action) => {
                    if (action.payload.status == "SUCCESS") {
                        state.breadCrumb = action.payload.data.breadCrumbs;
                        state.filterCategories = action.payload.data.categories;
                        state.filterBrands = action.payload.data.brands;
                        state.minPrice = action.payload.data.minPrice;
                        state.maxPrice = action.payload.data.maxPrice;
                    }
                })
                .addCase(fetchBrandFilters.rejected, (state, action) => {
                    state.error = action.error.message;
                })
                .addCase(fetchProductGroupFilters.fulfilled, (state, action) => {
                    if (action.payload.status == "SUCCESS") {
                        state.breadCrumb = action.payload.data.breadCrumbs;
                        state.filterCategories = action.payload.data.categories;
                        state.filterBrands = action.payload.data.brands;
                        state.minPrice = action.payload.data.minPrice;
                        state.maxPrice = action.payload.data.maxPrice;
                    }
                })
                .addCase(fetchProductGroupFilters.rejected, (state, action) => {
                    state.error = action.error.message;
                })
                .addCase(addMontlyListCart.pending, (state, action) => {
                    state.monthlyListCartSuccess = false;
                })
                .addCase(addMontlyListCart.fulfilled, (state, action) => {
                    if (action.payload.status == "SUCCESS") {
                        state.monthlyListCartSuccess = true;
                    }
                })
                .addCase(addMontlyListCart.rejected, (state, action) => {
                    state.error = action.error.message;
                })
                .addCase(addProductReview.fulfilled, (state, action) => {
                    if (action.payload.status == "SUCCESS") {
                        console.log("success");
                    }
                })
                .addCase(addProductReview.rejected, (state, action) => {
                    state.error = action.error.message;
                })
                .addCase(fetchProductGroup.fulfilled, (state, action) => {
                    if (action.payload.status == "SUCCESS") {
                        state.productGroupList = [...state.productGroupList, ...action.payload.data];
                        state.completed = true;
                    }
                })
                .addCase(fetchProductGroup.rejected, (state, action) => {
                    state.error = action.error.message;
                });

        //        builder.addCase(removeEmployee.fulfilled, (state, action) => {
        //            state.employeeList = state.employeeList.filter(
        //                    (item) => item._id != action.payload
        //            );
        //            state.response = "delete";
        //        });
        //
        //        builder.addCase(modifiedEmployee.fulfilled, (state, action) => {
        //            const updateItem = action.payload;
        //            console.log(updateItem);
        //            const index = state.employeeList.findIndex(
        //                    (item) => item._id === updateItem._id
        //            );
        //            if (index !== -1) {
        //                state.employeeList[index] = updateItem;
        //            }
        //            state.response = "update";
        //        });
    },
});

export default productSlice.reducer;
export const {
    changeStateTrue,
    changeStateFalse,
    clearResponse,
    changeLoading,
    clearProduct,
    clearSearchProduct
} = productSlice.actions;
