import { useEffect, useState, useRef }
from 'react';
import { Button, Modal, Form, Col, Row, Stack, Tabs, Tab, Nav, Alert, Image }
from 'react-bootstrap';
import { library }
from '@fortawesome/fontawesome-svg-core';
import { fas }
from '@fortawesome/free-solid-svg-icons';
import { fab }
from '@fortawesome/free-brands-svg-icons';
import { far }
from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon }
from '@fortawesome/react-fontawesome';
import '../static/css/App.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// import banner from '../static/images/login_banner.jpg';
import { setCredentials } from '../features/auth/authSlice';
import { fetchCart } from '../features/cartSlice';
import { useLoginMutation, useSendotpMutation } from '../features/auth/authApiSlice'

        library.add(fas, fab, far);
function Login(props) {
    const [activeTab, setActiveTab] = useState(props.tab);
    const [counter, setCounter] = useState(20);
    const [resendOtp, setResendOtp] = useState(false);
    const [otpScreen, setOtpScreen] = useState(props.otpScreen);
    const [login, {isLoading}] = useLoginMutation();
    const [sendotp, {isLoading1}] = useSendotpMutation();
    const [mobile, setMobile] = useState(props.mobileNo);
    const [otpNumbero, setOtpNumbero] = useState(null);
    const [otpNumbert, setOtpNumbert] = useState(null);
    const [otpNumberh, setOtpNumberh] = useState(null);
    const [otpNumberf, setOtpNumberf] = useState(null);
    const inputs = useRef([]);
    const dispatch = useDispatch()
    const userRef = useRef()
    const errRef = useRef()
    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [showErrMsg, setShowErrMsg] = useState(false);
    const navigate = useNavigate();
    const banner = process.env.REACT_APP_IMAGE_URL + '/home/whygrace.png';
    const num1 = useRef();
    const num2 = useRef();
    const num3 = useRef();
    const num4 = useRef();
    const {show, onHide} = props;
    // Function to switch tabs
    const handleTabChange = (tabId) => {
        setActiveTab(tabId);
    };
    // Function to switch tabs
    const handleOTPScreen = () => {
        setOtpScreen(true);
    };
    const handleOtp1Change = (value) => {
        if (value) {
            num2.current.focus();
        }

    }

    const handleOtp2Change = (value) => {
        if (value) {
            num3.current.focus();
        }
    }

    const handleOtp3Change = (value) => {
        if (value) {
            num4.current.focus();
        }
    }

    const handleOtp4Change = (value) => {
        if (value) {
            setOtpNumbero(num1.current.value);
            setOtpNumbert(num2.current.value);
            setOtpNumberh(num3.current.value);
            setOtpNumberf(value);
        }
    }

    useEffect(() => {
        if (otpScreen) {
            counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
            if (counter == 0) {
                setResendOtp(true);
            }
        }
    }, [otpScreen, counter]);
    useEffect(() => {
        setActiveTab(props.tab);
        setOtpScreen(props.otpScreen);
        setCounter(20);
        setResendOtp(false);
        setMobile(props.mobileNo);
    }, [props]);
    useEffect(() => {
        setErrMsg('');
        setShowErrMsg(false);
        setOtpNumbero(null);
        setOtpNumbert(null);
        setOtpNumberh(null);
        setOtpNumberf(null);
    }, [user, pwd, mobile])

    const backToLogin = () => {
        setActiveTab('login-mobile');
        setOtpScreen(false);
        setCounter(20);
        setResendOtp(false);
        setMobile(mobile);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const userData = await login({email: user, password: pwd, type: 'EMAIL'}).unwrap()
            // console.log('creadentials', userData);
            if (userData.status === "SUCCESS") {
                dispatch(setCredentials({...userData, user}));
                dispatch(fetchCart());
            } else {
                setShowErrMsg(true);
                setErrMsg(userData.message);
            }

            setUser('')
            setPwd('')
            // navigate('/')
            onHide(true);
        } catch (err) {
//            if (!err?.originalStatus) {
//                // isLoading: true until timeout occurs
//                setErrMsg('No Server Response');
//            } else if (err.originalStatus === 400) {
//                setErrMsg('Missing Username or Password');
//            } else if (err.originalStatus === 401) {
//                setErrMsg('Unauthorized');
//            } else {
//                setErrMsg('Login Failed');
//            }
//            // errRef.current.focus();
        }
    }
    const sendOtp = async (e) => {
        if (mobile.length != 10) {
            setShowErrMsg(true);
            setErrMsg("Invalid mobile number");
        } else {
            e.preventDefault();
            setOtpNumbero(null);
            setOtpNumbert(null);
            setOtpNumberh(null);
            setOtpNumberf(null);
            try {
                const userData = await sendotp({"mobileNo": mobile}).unwrap()
//             console.log('creadentials', userData);
                if (userData.status === "SUCCESS") {
                    handleOTPScreen();
//                dispatch(setCredentials({...userData, user}));
                } else {
                    setShowErrMsg(true);
                    setErrMsg(userData.message);
                }
            } catch (err) {
//            if (!err?.originalStatus) {
//                // isLoading: true until timeout occurs
//                setErrMsg('No Server Response');
//            } else if (err.originalStatus === 400) {
//                setErrMsg('Missing Username or Password');
//            } else if (err.originalStatus === 401) {
//                setErrMsg('Unauthorized');
//            } else {
//                setErrMsg('Login Failed');
//            }
//            // errRef.current.focus();
            }

        }
    }
    const mobileLogin = async (e) => {
        e.preventDefault();
        const otp = otpNumbero + otpNumbert + otpNumberh + otpNumberf;
        let details = {"mobileNo": mobile,
            "OTP": otp,
            "type": "OTP"
        };
        try {
            const userData = await login(details).unwrap()
            // console.log('creadentials', userData);
            if (userData.status === "SUCCESS") {
                dispatch(setCredentials({...userData, user}));
                onHide(true);
                dispatch(fetchCart());
            } else {
                setShowErrMsg(true);
                setErrMsg(userData.message);
            }

        } catch (err) {
//            if (!err?.originalStatus) {
//                // isLoading: true until timeout occurs
//                setErrMsg('No Server Response');
//            } else if (err.originalStatus === 400) {
//                setErrMsg('Missing Username or Password');
//            } else if (err.originalStatus === 401) {
//                setErrMsg('Unauthorized');
//            } else {
//                setErrMsg('Login Failed');
//            }
//            // errRef.current.focus();
        }
    }
    const handleUserInput = (e) => setUser(e.target.value)

    const handlePwdInput = (e) => setPwd(e.target.value)

    return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered            
                >
                <Row className="mx-0">
                    <Col md={4} className="d-none d-sm-block px-0">
                    <Image src={banner} className="mw-100" rounded />
                    </Col>
                    <Col md={8} className="px-0">
                    <Tabs
                        activeKey={activeTab}
                        id="uncontrolled-tab-example"
                        className="mb-3 d-none"
                        >
                        <Tab eventKey="login-mobile" title="login-mobile">
                            <Modal.Header closeButton className="p-5 pt-3 pb-3 border-bottom-0">
                                <Modal.Title id="contained-modal-title-vcenter">
                                    Login or Register
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="px-5">
                                {
                                    showErrMsg && <Alert key="danger" variant="danger" dismissible onClose={() => setShowErrMsg(false)}>
                                        {errMsg}
                                    </Alert>
                                }
                                {!otpScreen && <Form autoComplete="off" >
                                    <Form.Group className="mb-2" controlId="mobile_number">
                                        <Form.Label>Mobile Number</Form.Label>
                                        <Row>
                                            <Col md="12">
                                            <Form.Control type="text" autoComplete="off" className="formText" size="lg" placeholder="Enter mobile number" value={mobile}  onChange={e => setMobile(e.target.value)} name="mobile_number"/>
                                            </Col>
                                            <Col md="12" className="mt-3">
                                            <Button type="button" size="lg" className="px-5  fs-6 w-100" onClick={sendOtp}>Get OTP</Button>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    <Form.Label className="login-change-text fw-bold cursor-pointer" onClick={() => setActiveTab('login-email')}>
                                        Login using email
                                    </Form.Label>
                                    <br />
                                    <Form.Label className="login-change-text fw-bold cursor-pointer" onClick={props.onRegister}>
                                        Dont have an account Register Here
                                    </Form.Label>
                                </Form>}
                                {otpScreen && <Form>
                                    <Form.Group className="mb-2">
                                        <Form.Label>Enter OTP</Form.Label>
                                        <Row>                                       
                                            <Col md="3">
                                            <Form.Control type="text" pattern="\d*" maxLength="1" autoComplete="off" className="formText text-center" size="lg"  onChange={e => handleOtp1Change(e.target.value)} name="otp1" ref={num1}/>
                                            </Col>
                                            <Col md="3">
                                            <Form.Control type="text" pattern="\d*" maxLength="1" autoComplete="off" className="formText text-center" size="lg"   onChange={e => handleOtp2Change(e.target.value)} name="otp2" ref={num2}/>
                                            </Col>
                                            <Col md="3">
                                            <Form.Control type="text" pattern="\d*" maxLength="1" autoComplete="off" className="formText text-center" size="lg"   onChange={e => handleOtp3Change(e.target.value)} name="otp3" ref={num3}/>
                                            </Col>
                                            <Col md="3">
                                            <Form.Control type="text" pattern="\d*" maxLength="1" autoComplete="off" className="formText text-center" size="lg"   onChange={e => handleOtp4Change(e.target.value)} name="otp4" ref={num4}/>
                                            </Col>                                            
                                        </Row>
                                    </Form.Group>
                                    <Form.Group controlId="resend-otp">
                                        <Row>
                                            <Form.Label className="resend-text col-6">
                                                Resend OTP After 0:{counter < 10 ? `0${counter}` : counter} sec
                                            </Form.Label>
                                            {resendOtp &&
                                                    <Form.Label className="login-change-text fw-bold col-6 text-end cursor-pointer" onClick={() => {
                                                                                            setCounter(20);
                                                                                            setResendOtp(false)
                                                                                                    }}>
                                                        Resend OTP
                                                    </Form.Label>
                                            }
                                        </Row>
                                    </Form.Group>
                                    <Col md="12" className="mt-4">
                                    <Button type="submit" size="lg" className="px-5 w-100" onClick={mobileLogin}>Login</Button>
                                    </Col>
                                    <Col md="12" className="mt-4 text-end">
                                    <Form.Label className="login-change-text fw-bold cursor-pointer fs-5" onClick={backToLogin}>
                                        <FontAwesomeIcon icon="fa-solid fa-arrow-left-long" /> Back
                                    </Form.Label>                                    
                                    </Col>
                                </Form>
                                }
                            </Modal.Body>                            
                        </Tab>
                        <Tab eventKey="login-email" title="login-email">
                            <Modal.Header closeButton className="p-5 pt-3 pb-3 border-bottom-0">
                                <Modal.Title id="contained-modal-title-vcenter">
                                    Login or Register
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="px-5">
                                {
                                    showErrMsg && <Alert key="danger" variant="danger" dismissible>
                                        {errMsg}
                                    </Alert>
                                }
                                <Form autoComplete="off">
                                    <Form.Group className="mb-2" controlId="username">
                                        <Form.Label>Email Address</Form.Label>
                                        <Form.Control type="text"
                                                      ref={userRef}
                                                      value={user}
                                                      onChange={handleUserInput}
                                                      autoComplete="off"
                                                      required className="formText"
                                                      size="lg"
                                                      placeholder="Enter your email address" />
                                    </Form.Group>
                                    <Form.Group className="mb-2" controlId="password">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control type="password"
                                                      autoComplete="off"
                                                      required
                                                      className="formText"
                                                      size="lg"
                                                      placeholder="Enter your password"                                                  
                                                      onChange={handlePwdInput}
                                                      value={pwd}
                                                      />
                                    </Form.Group>
                                    <Button type="button" size="lg" className="px-5 mt-2 fs-6 w-100" onClick={handleSubmit}>Log in</Button>
                                    <Form.Label className="login-change-text fw-bold cursor-pointer mt-4" onClick=                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    {() => setActiveTab('login-mobile')}>
                                        Login using mobile number
                                    </Form.Label>
                                    <br />
                                    <Form.Label className="login-change-text fw-bold cursor-pointer" onClick={props.onRegister}>
                                        Dont have an account Register Here
                                    </Form.Label>
                                </Form>
                            </Modal.Body>
                        </Tab>
                    </Tabs>
                    </Col>   
                </Row>
            </Modal>
            );
}

export default Login;

