import { useDispatch }
from "react-redux";
import { useState, useEffect }
from "react";
import { Container, Row, Col, Image, Breadcrumb, Table, Card, ListGroup, Tab, Tabs, Nav, Button, Form }
from 'react-bootstrap';
import { library }
from '@fortawesome/fontawesome-svg-core';
import { fas }
from '@fortawesome/free-solid-svg-icons';
import { fab }
from '@fortawesome/free-brands-svg-icons';
import { far }
from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon }
from '@fortawesome/react-fontawesome';
import '../../static/css/App.css';
import cod from '../../static/images/cash-on-delivery.png';
import cad from '../../static/images/card-on-delivery.png';
import sodexoimg from '../../static/images/sodexo.png';
//import { useGetCartQuery, useAddCartMutation } from "../features/productApi";
library.add(fas, fab, far);
function PaymentDetails(props) {

    const [paymentType, setPaymentType] = useState(null);

    useEffect(() => {
        props.setdeliveryDetails({...props.deliveryDetails, paymentType: paymentType});
    }, [paymentType]);
    return (
            <Row className="justify-content-center">
                <Card className={"bg-body-tertiary m-2 col-5 cursor-pointer " + (paymentType == 1 ? 'active-address' : '')} onClick={(k) => setPaymentType(1)}>
                <Card.Body>
                    <Form.Check type="radio" id={"check-api-radio-1"}>
                        <Form.Check.Input className=" cursor-pointer d-none"  type="radio"/>
                        <Image src={cod} />
                        <Form.Check.Label className=" cursor-pointer ps-2">
                            Cash On delivery
                        </Form.Check.Label>
                    </Form.Check>
                </Card.Body>
                </Card>
                <Card className={"bg-body-tertiary m-2 col-5 cursor-pointer " + (paymentType == 2 ? 'active-address' : '')} onClick={(k) => setPaymentType(2)}>
                <Card.Body>
                    <Form.Check type="radio" id={"check-api-radio-2"}>
                        <Form.Check.Input className=" cursor-pointer d-none"  type="radio"/>
                        <Image src={cad} />
                        <Form.Check.Label className=" cursor-pointer ps-2">
                            Card On delivery
                        </Form.Check.Label>
                    </Form.Check>
                </Card.Body>
                </Card>
               
            </Row>
            )

}

export default PaymentDetails;