import { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Form,
  Col,
  Row,
  Stack,
  Tabs,
  Tab,
  Nav,
  Alert,
  Image,
} from "react-bootstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../static/css/App.css";
import { useSignupMutation } from "../features/auth/authApiSlice";
// import banner from "../static/images/login_banner.jpg";
library.add(fas, fab, far);
function Register(props) {
  const { show, onHide, otpShow, mobileNo } = props;
  const [details, setDetails] = useState({
    name: "",
    email: "",
    mobile: "",
    password: "",
    dob: "",
  });
  const [errMsg, setErrMsg] = useState("");
  const [showErrMsg, setShowErrMsg] = useState(false);
  const [signup, { isLoading }] = useSignupMutation();
  
  const banner = process.env.REACT_APP_IMAGE_URL + '/home/whygraceregister.png';
  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowErrMsg(false);
    try {
      if (
        details.name == "" ||
        details.email == "" ||
        details.password == "" ||
        details.dob == ""
      ) {
        setShowErrMsg(true);
        setErrMsg("All details required");
      } else if (details.mobile.length != 10) {
        setShowErrMsg(true);
        setErrMsg("Mobile number length should be 10");
      } else {
        const userData = await signup(details).unwrap();
        console.log(userData);
        if (userData.status === "SUCCESS") {
          otpShow(true);
        } else {
          setShowErrMsg(true);
          setErrMsg(userData.message);
        }
      }
      //            onHide(true);
    } catch (err) {
      if (!err?.originalStatus) {
        // isLoading: true until timeout occurs
        setErrMsg("No Server Response");
      } else if (err.originalStatus === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err.originalStatus === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Login Failed");
      }
      // errRef.current.focus();
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === "mobile") {
      mobileNo(value);
    }
  };
  useEffect(() => {
    setDetails({ name: "", email: "", mobile: "", password: "" });
    setShowErrMsg(false);
    setErrMsg("");
  }, []);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Row className="mx-0">
        <Col md={4} className="d-none d-sm-block px-0  py-0">
          <Image src={banner} className="h-100 loleft-img mw-100 d-inline-block" />
        </Col>
        <Col md={8} className="px-0">
          <Modal.Header closeButton className="p-5 pt-2 pb-3 border-bottom-0">
            <Modal.Title id="contained-modal-title-vcenter">
              Register
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-5 pt-2">
            <Form autocomplete="off">
              <Row>
                {showErrMsg && (
                  <Alert
                    key="danger"
                    variant="danger"
                    dismissible
                    onClose={() => setShowErrMsg(false)}
                  >
                    {errMsg}
                  </Alert>
                )}
                <Form.Group
                  className="mb-1 col-12 "
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="required">
                    Full Name <span class="text-danger">*</span>{" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    required
                    autocomplete="off"
                    required
                    className="formText"
                    size="lg"
                    placeholder="Enter your full name"
                    onChange={handleChange}
                    name="name"
                    value={details.name}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-1 col-12"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>
                    Email Address <span class="text-danger">*</span>{" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    required
                    autocomplete="off"
                    required
                    className="formText"
                    size="lg"
                    placeholder="Enter your email address"
                    onChange={handleChange}
                    name="email"
                    value={details.email}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-1 col-12"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>
                    Mobile Number <span class="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    required
                    minLength="10"
                    maxLength="10"
                    autocomplete="off"
                    required
                    className="formText w-100 text-start"
                    size="lg"
                    placeholder="Enter your mobile number"
                    onChange={handleChange}
                    name="mobile"
                    value={details.mobile}
                  />
                </Form.Group>

                <Form.Group
                  className="mb-1 col-12"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>
                    Password <span class="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="password"
                    required
                    autocomplete="off"
                    required
                    className="formText"
                    size="lg"
                    placeholder="Enter your password"
                    onChange={handleChange}
                    name="password"
                    value={details.password}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-1 col-12"
                  controlId="exampleForm.ControlInput1"
                >
                  {/* <Form.Label>Date Of Birth</Form.Label>
                  <Form.Control
                    type="date"
                    required
                    autocomplete="off"
                    required
                    className="formText"
                    max={new Date().toISOString().slice(0, 10)}
                    size="lg"
                    placeholder="Enter your date of birth"
                    onChange={handleChange}
                    name="dob"
                    value={details.dob}
                  /> */}
                </Form.Group>
                <Form.Label className="login-change-text fw-bold">
                  By signing up, you agree to our Terms & Conditions
                </Form.Label>
                <Button
                  type="submit"
                  onClick={handleSubmit}
                  size="lg"
                  className="px-5 mt-4 col-12"
                >
                  Sign Up
                </Button>
              </Row>
              <Form.Label
                className="login-change-text fw-bold cursor-pointer mt-4"
                onClick={props.onLogin}
              >
                Already have an account? Login here
              </Form.Label>
            </Form>
          </Modal.Body>
        </Col>
      </Row>
    </Modal>
  );
}

export default Register;
