import { fetchMontlyList, addMonthlyList, deleteMontlyList, addMontlyListCart, changeLoading } from "../../features/productSlice";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { fetchCart } from "../../features/cartSlice";
import {
    Container, Form, Button, Row, Table, Col, Image, Modal, InputGroup, ToastContainer,
    Toast
} from "react-bootstrap";
import { NavLink } from "react-router-dom";

function MonthlyList() {
    const dispatch = useDispatch();
    const { monthlyListProduct, monthlyListCartSuccess, deleteSuccess } = useSelector((state) => state.product);

    const [cartId, setCartId] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [products, setProducts] = useState([]); // Local state to hold products
    const [show, setShow] = useState(false);

    // Fetch the monthly list once on mount
    useEffect(() => {
        if (monthlyListProduct.length === 0) {
            dispatch(fetchMontlyList());
        } else {
            setProducts(monthlyListProduct); // Set initial products state
        }
    }, [dispatch, monthlyListProduct]);

    // Handle Increment
    const handleIncrement = (pid, iitem, qty) => {
        if (isLoading)
            return; // Prevent multiple clicks
        setIsLoading(true);

        // Create a new array with the updated quantity
        const updatedProducts = products.map((product) =>
            product.productItemId === iitem
                ? { ...product, quantity: product.quantity + 1 } // Update quantity immutably
                : product
        );

        setProducts(updatedProducts); // Update the state with the new products

        // Dispatch the increment action
        dispatch(addMonthlyList({
            productId: pid,
            productItemId: iitem,
            quantity: qty + 1,
        }));

        setIsLoading(false);
    };

    // Handle Decrement
    const handleDecrement = (pid, iitem, qty) => {
        if (isLoading)
            return; // Prevent multiple clicks
        setIsLoading(true);

        // Create a new array with the updated quantity
        const updatedProducts = products.map((product) =>
            product.productItemId === iitem && product.quantity > 1
                ? { ...product, quantity: product.quantity - 1 } // Update quantity immutably
                : product
        );

        setProducts(updatedProducts); // Update the state with the new products

        // Dispatch the decrement action
        dispatch(addMonthlyList({
            productId: pid,
            productItemId: iitem,
            quantity: qty - 1,
        }));

        setIsLoading(false);
    };

    const handleMonthlyDelete = (id) => {
        if (id) {
            setCartId([id]);
        }
        setModalShow(true);
    };

    const handleDelete = () => {
        dispatch(deleteMontlyList({ wishlistId: cartId.join(",") }));
        setModalShow(false);
    };

    const addAllCart = () => {
        setCartId([]);
        dispatch(addMontlyListCart(null));

    };

    const addCart = () => {
        dispatch(addMontlyListCart(cartId));
        setCartId([]);

    };

    const handleClose = () => {
        setModalShow(false);
        setCartId([]);
    };

    const handleSelectId = (id) => {
        if (cartId && cartId.includes(id)) {
            setCartId(cartId.filter((el) => el !== id));
        } else {
            setCartId([...cartId, id]);
        }
    };

    // Fetch the cart when the monthly list is updated or changed
    useEffect(() => {
        dispatch(fetchCart());
        if (monthlyListCartSuccess) {
            setShow(true);
        } else {
            setShow(false);
        }
    }, [monthlyListCartSuccess]);

    useEffect(() => {
        if (deleteSuccess) {
            dispatch(changeLoading());
            dispatch(fetchMontlyList());
            setCartId([]);
        }
    }, [deleteSuccess]);

    return (
        <div>
            <Container fluid>
                <Row>
                    <Col xs={12} sm={4} className="text-start">
                        <Button className="bg-secondary border-secondary" onClick={(e) => handleMonthlyDelete(null)} disabled={cartId.length <= 0 ? true : false}>
                            Delete
                        </Button>
                    </Col>
                    <Col xs={12} sm={8} className="text-end">
                        <Button className=" me-5" onClick={addCart} disabled={cartId.length <= 0 ? true : false} >
                            Add selected items to cart
                        </Button>
                        <Button className="bg-dark border-dark" onClick={addAllCart}>
                            Add all to Cart
                        </Button>
                    </Col>
                </Row>

                <Table bordered responsive hover className="mt-3">
                    <tbody>
                        {products.length > 0 &&
                            products.map((ml, index) => (
                                <tr key={index}>
                                    <td className="col-1 text-center align-middle">
                                        {ml.stock > 0 && (
                                            <Form.Check
                                                className="monthlyListCheck"
                                                type="checkbox"
                                                onChange={() => handleSelectId(ml.wishlistId)}
                                                id={`ml-${index}`}
                                                checked={cartId && cartId.includes(ml.wishlistId)}
                                            />
                                        )}
                                    </td>
                                    <td className="col-6">
                                        <Row className="border-end">
                                            <Col sm={2}>
                                                <NavLink to={`/pd/${ml.slug}`}>
                                                    <Image
                                                        src={`${process.env.REACT_APP_IMAGE_URL}/uploads/${ml.image[0]}`}
                                                        thumbnail
                                                        className="mt-2"
                                                    />
                                                </NavLink>
                                            </Col>
                                            <Col sm={10}>
                                                <p>
                                                    <NavLink to={`/pd/${ml.slug}`}>
                                                        <b>{ml.productName}</b>
                                                    </NavLink>
                                                </p>
                                                <p className="text-secondary">{ml.brandName}</p>
                                                <p>
                                                    <b>
                                                        {ml.unit} {ml.unitName}
                                                    </b>
                                                </p>
                                            </Col>
                                        </Row>
                                    </td>
                                    <td className="col-4 align-middle">
                                        <div className="input-group mb-3 w-75 m-auto">
                                            <InputGroup.Text
                                                className="cursor-pointer"
                                                onClick={() => handleDecrement(ml.productID, ml.productItemId, ml.quantity)}
                                            >
                                                -
                                            </InputGroup.Text>
                                            <input
                                                type="number"
                                                min="1"
                                                className="form-control p-1 text-center"
                                                value={ml.quantity}
                                                aria-label="Amount"
                                            />
                                            <InputGroup.Text
                                                className="cursor-pointer"
                                                onClick={() => handleIncrement(ml.productID, ml.productItemId, ml.quantity)}
                                            >
                                                +
                                            </InputGroup.Text>
                                        </div>
                                    </td>
                                    <td className="col-2">
                                        <p className="text-secondary">
                                            MRP: <s>{ml.mrpPrice.toFixed(2)}</s>&nbsp;
                                            <span className="text-dark">
                                                <b>{ml.salePrice.toFixed(2)}</b>
                                            </span>
                                        </p>
                                        <p className="text-secondary">
                                            {ml.quantity} x {ml.salePrice.toFixed(2)} ={" "}
                                            <span className="text-dark">
                                                <b>{(ml.quantity * ml.salePrice).toFixed(2)}</b>
                                            </span>
                                        </p>
                                    </td>
                                    <td className="col-1 text-center align-middle">
                                        <p className="text-danger cursor-pointer" onClick={() => handleMonthlyDelete(ml.wishlistId)}>
                                            Delete
                                        </p>
                                    </td>
                                </tr>
                            ))}

                        {products.length <= 0 && (
                            <tr className="col-1 text-center align-middle">
                                <td className="text-danger">No list as of now.</td>
                            </tr>
                        )}
                    </tbody>
                </Table>

            </Container>
            <ToastContainer
                className="p-3"
                position="middle-center"
                style={{ zIndex: 1 }}
            >
                <Toast
                    bg="success"
                    onClose={() => setShow(false)}
                    show={show}
                    delay={3000}
                    autohide
                >
                    <Toast.Body className="text-white text-center">
                        <b>Added successfully</b>
                    </Toast.Body>
                </Toast>
            </ToastContainer>
            <Modal size="md" centered backdrop="static" keyboard={false} show={modalShow}>
                <Modal.Header>
                    <Modal.Title className="text-danger m-auto h3">Warning</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="h4 text-center">Are you sure? You want to delete?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleDelete}>Yes Delete!</Button>
                    <Button className="bg-secondary border-secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default MonthlyList;
