import { useDispatch, useSelector } from "react-redux";
import { addToCart, removeFromCart } from "../features/cartSlice";
import { fetchSearchProductList, changeStateTrue, changeStateFalse, changeLoading, clearProduct } from "../features/searchSlice";
import { selectCurrentToken } from "../features/auth/authSlice";
import { useState, useEffect } from "react";
import { useSearchParams, NavLink } from "react-router-dom";
import { Container, Row, Col, Button, Card, Image } from "react-bootstrap";
import Login from "../common/login";
import Register from "../common/register";
import "../static/css/App.css";
import { use } from "react";

function Search() {
    const [searchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false);
    const [productSlug, setProductSlug] = useState(null);
    const [loginModalShow, setLoginModalShow] = useState(false);
    const [registerModalShow, setRegisterModalShow] = useState(false);
    const [mobileNo, setMobileNo] = useState("");
    const [otpScreen, setOtpScreen] = useState(false);
    const [productListings, setProductListings] = useState(null);

    const dispatch = useDispatch();
    const token = useSelector(selectCurrentToken);
    const { cartListNew } = useSelector((state) => state.cart);
    const { sproductList, completed } = useSelector((state) => state.search);

    // Fetch search results when query changes
    const fetchData = () => {
       
        setIsLoading(true);
        if (productSlug) {
            dispatch(clearProduct());
            dispatch(fetchSearchProductList({ search: productSlug }));
        }
    };

    // Add item to cart if user is logged in, otherwise show login modal
    const handleAddCart = (item, amount) => {
        if (token) {
            dispatch(addToCart({ productItemId: item, quantity: 1, totalQuantity: 1, salesPrice: amount }));
        } else {
            setLoginModalShow(true);
        }
    };

    // Update cart quantity by incrementing
    const handleIncrement = (item, amount) => {
        dispatch(addToCart({ productItemId: item, quantity: 1, totalQuantity: 1, price: amount, status: true }));
    };

    // Update cart quantity by decrementing
    const handleDecrement = (item, amount) => {
        dispatch(removeFromCart({ productItemId: item, price: amount }));
    };

    // Handle image load errors
    const handleErrorImg = (e) => {
        e.target.src = process.env.REACT_APP_NO_IMAGE;
    };

    // Set productSlug when the search params change
    useEffect(() => {
        const query = searchParams.get("q");
        console.log(query, 'query');
        setProductSlug(query);
        window.scrollTo(0, 0);
    }, [searchParams]);

    // Fetch data on productSlug change
    useEffect(() => {
        dispatch(changeLoading());
        fetchData();
        dispatch(changeStateTrue());
    }, [productSlug]);

    // Stop loading when fetch is complete
    useEffect(() => {
        if (completed) {
            setIsLoading(false);
        }
    }, [completed]);

    // Infinite scroll listener for fetching more products
    useEffect(() => {
        const handleScroll = () => {
            const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
            if (scrollTop + clientHeight >= scrollHeight - 20 && !isLoading) {
                fetchData();
                dispatch(changeStateTrue());
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [isLoading]);

    // Combine cart and product data

    useEffect(() => {
        // console.log(productListings, 'productListings');
        console.log(sproductList, 'sproductList');
        setProductListings(null);
        const productListing = sproductList.map((product) => {
            const cartItem = cartListNew.find((item) => item.productItemId === product.productItemId);
            return {
                ...product,
                cartExists: !!cartItem,
                cartQty: cartItem ? cartItem.quantity : 0,
            };
        });
        setProductListings(productListing);
    }, [sproductList]);

    return (
        <div>
            <Container fluid="xl" className="px-md-5 px-3 px-xl-3">
                <Row className="my-5">
                    {productListings && productListings.map((product, index) => (
                        <Col className="col-md-3 my-2" key={index}>
                            <Card className="PCard">
                                <NavLink href={`/pd/${product.slug}`} className="proImage">
                                    <Card.Img
                                        variant="top"
                                        className="product-img"
                                        onError={handleErrorImg}
                                        src={`${process.env.REACT_APP_IMAGE_URL}/uploads/${product.image?.[0] || ''}`}
                                    />
                                </NavLink>
                                <Card.Body>
                                    <Card.Title>
                                        <span className="price-section text-danger">
                                            ₹{product.salePrice?.toFixed(2)}
                                        </span>
                                        {product.salePrice && product.salePrice.toFixed(2) !== product.mrpPrice.toFixed(2) && (
                                            <span className="mrp-section text-muted">
                                                &nbsp; MRP.<s>₹{product.mrpPrice.toFixed(2)}</s>
                                            </span>
                                        )}
                                    </Card.Title>
                                    <NavLink to={`/pd/${product.slug}`}>
                                        <Card.Text className="float-start w-100 mb-3 mt-2 product-name">
                                            <span className="product-name-section d-block text-truncate float-start">
                                                {product.productName?.toLowerCase()}
                                            </span>
                                            <span className="brand-section d-block text-truncate ps-2 text-muted float-start">
                                                ({product.brandName})
                                            </span>
                                        </Card.Text>
                                    </NavLink>
                                    <Col md={7} className="float-start" style={{ fontSize: "15px", height: "35px" }}>
                                        <span>
                                            {product.unit} {product.unitName} - Rs.{product.salePrice?.toFixed(2)}
                                        </span>
                                    </Col>
                                    <Col md={5} className="float-end text-end ps-2">
                                        {product.stock > 0 && !product.cartExists ? (
                                            <Button
                                                variant="danger"
                                                className="btn-sm w-100 addcartBtn"
                                                onClick={() => handleAddCart(product.productItemId, product.salePrice?.toFixed(2))}
                                            >
                                                + Add
                                            </Button>
                                        ) : (
                                            product.stock > 0 && (
                                                <div className="input-group variantInput">
                                                    <div className="input-group-prepend">
                                                        <span
                                                            className="input-group-text cursor-pointer"
                                                            onClick={() => handleDecrement(product.productItemId, product.salePrice?.toFixed(2))}
                                                        >
                                                            -
                                                        </span>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        className="form-control p-1 text-center"
                                                        value={product.cartQty}
                                                        disabled
                                                    />
                                                    <div className="input-group-append">
                                                        <span
                                                            className="input-group-text cursor-pointer"
                                                            onClick={() => handleIncrement(product.productItemId, product.salePrice?.toFixed(2))}
                                                        >
                                                            +
                                                        </span>
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </Col>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>

            {/* Modals for Login and Register */}
            <Login
                show={loginModalShow}
                tab="login-mobile"
                otpScreen={otpScreen}
                mobileNo={mobileNo}
                onHide={() => {
                    setLoginModalShow(false);
                    setOtpScreen(false);
                }}
                onRegister={() => {
                    setRegisterModalShow(true);
                    setLoginModalShow(false);
                    setOtpScreen(false);
                }}
            />
            <Register
                show={registerModalShow}
                onHide={() => setRegisterModalShow(false)}
                mobileNo={setMobileNo}
                otpShow={() => {
                    setRegisterModalShow(false);
                    setLoginModalShow(true);
                    setOtpScreen(true);
                }}
            />
        </div>
    );
}

export default Search;
