import { useState, useEffect } from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import {
  fetchBrand,
  changeStateTrue,
  clearResponse,
} from "../features/brandSlice";
import { NavLink } from "react-router-dom";
import META from '../common/meta';
import { useDispatch, useSelector } from "react-redux";

function BrandsListing() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { brandList, error, response, completed } = useSelector(
    (state) => state.brand
  );
  const fetchData = () => {
    setIsLoading(true);
    dispatch(fetchBrand());
    dispatch(changeStateTrue());
  };
  useEffect(() => {
    dispatch(clearResponse());
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } =
        document.documentElement;
      if (scrollTop + clientHeight >= scrollHeight - 20 && !isLoading) {
        fetchData();
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [fetchData, isLoading]);

  useEffect(() => {
    if (completed) {
      setIsLoading(false);
    }
  }, [completed]);

  //    const {data: brands, isError: isbrandError, isLoading: isbrandLoading, isFetching: isbrandFetching, isSuccess: isbrandSuccess} = useGetAllBrandsQuery();
  return (
    <div>
                <META slug="brands" type="page"/>
      <Container  fluid="xl"  className="px-md-5 px-3 px-xl-3">
        {brandList && (
          <Col>
            <h3 className="px-0 py-3">Shop by brands</h3>
            <Row>
              {brandList &&
                brandList.map((brand, index) => (
                  <Col sm={3} key={index}>
                    <NavLink to={`/bd/${brand.slug}`}>
                      <Card className="border my-3">
                        <Card.Img
                          variant="top"
                          className="product-img p-2"
                          src={`https://gracesupermarket.s3.ap-southeast-2.amazonaws.com/uploads/${brand.image}`}
                        />
                      </Card>
                    </NavLink>
                  </Col>
                ))}
            </Row>
          </Col>
        )}
      </Container>
    </div>
  );
}

export default BrandsListing;
