import { configureStore } from "@reduxjs/toolkit";
import cartSlice from "../features/cartSlice";
import profileSlice from "../features/profileSlice";
import productSlice from "../features/productSlice";
import brandSlice from "../features/brandSlice";
import homeSlice from "../features/homeSlice";
import commonSlice from "../features/commonSlice";
import searchSlice from "../features/searchSlice";
import { productsApi } from "../features/productApi";
import { brandsApi } from "../features/brandApi";
import { categoriesApi } from "../features/categoryApi";
import { setupListeners } from '@reduxjs/toolkit/query';
import { apiSlice } from "./api/apiSlice"
import authReducer from '../features/auth/authSlice'
        const store = configureStore({
            reducer: {
                cart: cartSlice,
                product: productSlice,
                profile: profileSlice,
                brand: brandSlice,
                common:commonSlice,
                home:homeSlice,
                search:searchSlice,
                [productsApi.reducerPath]: productsApi.reducer,
                [brandsApi.reducerPath]: brandsApi.reducer,
                [categoriesApi.reducerPath]: categoriesApi.reducer,
                [apiSlice.reducerPath]: apiSlice.reducer,
                auth: authReducer
            },
            middleware: (getDefaultMiddleware) =>
                getDefaultMiddleware().concat(productsApi.middleware, brandsApi.middleware, categoriesApi.middleware, apiSlice.middleware),
        });

setupListeners(store.dispatch);
export default store;
