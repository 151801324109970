import { useState, useEffect } from "react";
import { Row, Col, Card, Container, ListGroup } from "react-bootstrap";
import { useGetAllCategoriesQuery } from "../features/categoryApi";
import { NavLink } from "react-router-dom";
import META from '../common/meta';

import { useDispatch, useSelector } from "react-redux";

function Categories() {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
//  const fetchData = () => {
//    setIsLoading(true);
//    dispatch(fetchBrand());
//    dispatch(changeStateTrue());
//  };
    const {data: categoryList} = useGetAllCategoriesQuery();

//  useEffect(() => {
//    const handleScroll = () => {
//      const { scrollTop, clientHeight, scrollHeight } =
//        document.documentElement;
//      if (scrollTop + clientHeight >= scrollHeight - 20 && !isLoading) {
//        fetchData();
//      }
//    };
//    window.addEventListener("scroll", handleScroll);
//    return () => {
//      window.removeEventListener("scroll", handleScroll);
//    };
//  }, [fetchData, isLoading]);
//
    useEffect(() => {
        window.scroll(0,0);
    }, [categoryList]);

    //    const {data: brands, isError: isbrandError, isLoading: isbrandLoading, isFetching: isbrandFetching, isSuccess: isbrandSuccess} = useGetAllBrandsQuery();
    return (
            <div>
            <META slug="all-categories" type="page"/>
                <Container  fluid="xl"  className="px-md-5 px-3 px-xl-3">
                    {categoryList && (
                                    <Col>
                                    <h3 className="px-0 py-3">All Categories</h3>
                                    {categoryList.data.map((cat1, index) => (
                                                                            <Row className="my-3">
                                                                                <Col md={2} className="parent-cat py-4">
                                                                                <h6 className="text-danger">{cat1.categoryName}</h6>
                                                                                <ListGroup variant="flush">
                                                                                    {cat1.subcategories.map((cat2, index) => (
                                                                                            <ListGroup.Item className="border-bottom-0 ps-0"><NavLink to={`/ct/${cat2.slug}`} className="text-decoration-none text-dark">{cat2.categoryName}</NavLink></ListGroup.Item>
                                                                                                                    ))}                                                                                                                                                                        
                                                                                </ListGroup>
                                                                                </Col>
                                                                                <Col md={10} className="sub-cat py-2">
                                                                                <Row>
                                                                                    {cat1.subcategories.map((cat2, index) => (
                                                                                                                            <Col md={4}>
                                                                                                                            <h6 className="text-dark fw-bold mt-3">{cat2.categoryName}</h6>
                                                                                                                            <ListGroup variant="flush">
                                                                                                                                {cat2.subsubcategories.map((cat3, index) => (
                                                                                                                                                                                            <ListGroup.Item className="border-bottom-0 ps-0 pb-0 text-muted"><NavLink to={`/ct/${cat3.slug}`} className="text-decoration-none text-muted">{cat3.categoryName}</NavLink></ListGroup.Item>
                                                                                                                                                                        ))}                                                                                                                                                                        
                                                                                                                            </ListGroup>
                                                                                                                            </Col>

                                                                                                            ))}    
                                                                                </Row>                                                                                
                                                                                </Col>
                                                                            </Row>
                                                                    ))}
                                    </Col>
                                        )}
                </Container>
            </div>
            );
}

export default Categories;
