import { useDispatch, useSelector } from "react-redux";

import { useState, useEffect } from "react";
import { useParams, useSearchParams, useNavigate  } from "react-router-dom";

function Redirect() {
    let [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    useEffect(() => {
        const token = searchParams.get("token");
        if (token) {
            localStorage.setItem('token', token);
            navigate("/");
            window.scrollTo(0, 0);
        }
    }, [searchParams]);


    return (
            <div>            
            
            </div>
            );
}
export default Redirect;
