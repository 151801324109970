import { NavLink } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
function OfferBanners( { data, title = null }) {
    return (
            <Row>
                <div className=" py-sm-3 pt-sm-2 my-4 px-0">
                    {title && <h3 className="px-0 ofTitle">{title}</h3>}
                    <div className="col-12 px-3 float-start mb-sm-3 mb-xs-0">
                        <Row>
                            {data.map((bnr, idx) => (
                                        <Col sm={4} xs={6} key={idx} className="p_banner">
                                        <Card className="border-0">
                                        <NavLink to={bnr.url}>
                                            <Card.Img
                                                variant="top"
                                                src={process.env.REACT_APP_IMAGE_URL + bnr.valueData}
                                                />
                                        </NavLink>
                                        </Card>
                                        </Col>
                                                    ))}
                        </Row>
                    </div>
                </div>
            </Row>
            );
}
export default OfferBanners;
