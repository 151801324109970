import { fetchMyProfile, updateMyProfile, clearState, mobileVerify } from "../../features/profileSlice";
import { useDispatch, useSelector }
from "react-redux";
import { useState, useEffect }
from "react";
import { Container, Form, Button, Row, Alert}
from 'react-bootstrap';
import { library }
from '@fortawesome/fontawesome-svg-core';
import { fas }
from '@fortawesome/free-solid-svg-icons';
import { fab }
from '@fortawesome/free-brands-svg-icons';
import { far }
from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon }
from '@fortawesome/react-fontawesome';
import '../../static/css/App.css';
library.add(fas, fab, far);


function MyProfile() {

    const dispatch = useDispatch();
    const {myProfile, error, updateState} = useSelector(
            (state) => state.profile
    );
    useEffect(() => {
        dispatch(clearState());
        dispatch(fetchMyProfile());
    }, [dispatch]);

    const [updateProfile, setUpdateProfile] = useState({name: "", mobile: "", email: ""});
    const [mobileUpdate, setMobileUpdate] = useState(false);
    const [otp, setOTP] = useState("");

    const handleProfileSubmit = () => {
        if (myProfile.name === updateProfile.name && myProfile.mobile === updateProfile.mobile && myProfile.email === updateProfile.email) {
            alert("No changes");
        } else {
            dispatch(updateMyProfile(updateProfile));
        }
    }
    const handleVerifySubmit = () => {
        if (otp === "") {
            alert("Please enter OTP");
        } else {
            dispatch(mobileVerify({"otp": otp}));
        }
    }

    const handleInputValue = (e) => {
        setUpdateProfile({
            ...updateProfile,
            [e.target.name]: e.target.value
        });
    }
    useEffect(() => {
        setUpdateProfile({name: myProfile.name, mobile: myProfile.mobile, email: myProfile.email});
    }, [myProfile]);
    useEffect(() => {
        console.log(otp);
    }, [otp]);
    useEffect(() => {
        if (updateState && error === "" && updateProfile.mobile !== "") {
            if ((myProfile.mobile !== updateProfile.mobile)) {
                setMobileUpdate(true);
            }
        }
    }, [updateState, updateProfile]);


    return (
            <div>
                <Container fluid className="">
                    {error && <Alert variant="danger" dismissible className="text-center">                        
                        <p className="mb-0">
                            {error}
                        </p>
                    </Alert>} 
                    {!mobileUpdate && <Form className="col-12 col-sm-6 m-auto">
                        <Form.Group className="mb-3" controlId="formFirstName">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control placeholder="First Name" value={updateProfile.name} name="name" onChange={handleInputValue}/>                            
                        </Form.Group>            
                        <Form.Group className="mb-3" controlId="formMobileNumber">
                            <Form.Label>Mobile Number</Form.Label>
                            <Form.Control placeholder="Mobile Number" value={updateProfile.mobile} name="mobile" onChange={handleInputValue}/>
                        </Form.Group>  
                        <Form.Group className="mb-3" controlId="formEmail">
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control type="email" placeholder="Email Address"  value={updateProfile.email}  name="email" onChange={handleInputValue}                                          />                            
                        </Form.Group>                         
            
                        <Button variant="primary" className="d-block m-auto" onClick={handleProfileSubmit}>
                            Update Profile
                        </Button>            
                    </Form>}
                    {mobileUpdate && <Form className="col-6 m-auto">                                  
                        <Form.Group className="mb-3" controlId="formOTP">
                            <Form.Label>Enter OTP</Form.Label>
                            <Form.Control placeholder="Enter OTP" name="otp" onChange={e => setOTP(e.target.value)}/>
                        </Form.Group>                                                
            
                        <Button variant="primary" className="d-block m-auto" onClick={handleVerifySubmit}>
                            Verify
                        </Button>            
                    </Form>}
                </Container>
            </div>
            );
}




export default MyProfile;
