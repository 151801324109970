import { fetchMyProfile, updateMyProfile, clearState, mobileVerify } from "../../features/profileSlice";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { Container, Form, Button, Alert } from 'react-bootstrap';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../static/css/App.css';

library.add(fas, fab, far);

function MyProfile() {
    const dispatch = useDispatch();
    const { myProfile, error, updateState } = useSelector((state) => state.profile);

    // State for holding updated profile data
    const [updateProfile, setUpdateProfile] = useState({ name: "", mobile: "", email: "" });
    const [mobileUpdate, setMobileUpdate] = useState(false);
    const [otp, setOTP] = useState("");

    // State for success and error messages
    const [message, setMessage] = useState("");
    const [messageType, setMessageType] = useState(""); // 'success' or 'danger'

    // Loading state for the button
    const [loading, setLoading] = useState(false);

    // Refetch guard state
    const [refetch, setRefetch] = useState(false);

    useEffect(() => {
        dispatch(clearState());
        dispatch(fetchMyProfile());
    }, [dispatch]);

    useEffect(() => {
        // If profile update is successful
        if (updateState && error === "") {


            setMessage("Profile updated successfully!");
            setMessageType("success");
            setLoading(false);

            setTimeout(() => {
                setMessage("");
                dispatch(clearState());
                dispatch(fetchMyProfile());
            }, 3000);
        }

        // Handle error during profile update
        if (error) {
            setMessage(error);
            setMessageType("danger");
            setLoading(false);  // Reset loading state if error occurs
        }
    }, [updateState, error, dispatch]);

    const handleProfileSubmit = () => {
        if (myProfile.name === updateProfile.name && myProfile.mobile === updateProfile.mobile && myProfile.email === updateProfile.email && myProfile.dob === updateProfile.dob) {
            alert("No changes");
        } else {
            setLoading(true);  // Set loading to true while the update is in progress
            dispatch(updateMyProfile(updateProfile));
        }
    };

    const handleVerifySubmit = () => {
        if (otp === "") {
            alert("Please enter OTP");
        } else {
            setLoading(true);  // Set loading to true while OTP verification is in progress
            dispatch(mobileVerify({ otp }));
        }
    };

    const handleInputValue = (e) => {
        setUpdateProfile({
            ...updateProfile,
            [e.target.name]: e.target.value,
        });
    };

    useEffect(() => {
        setUpdateProfile({
            name: myProfile.name,
            mobile: myProfile.mobile,
            email: myProfile.email,
            dob: myProfile.dob,
            gender: myProfile.gender,
        });
    }, [myProfile]);

    return (
        <div>
            <Container fluid className="">

                {message && (
                    <Alert variant={messageType} dismissible className="text-center">
                        <p className="mb-0">{message}</p>
                    </Alert>
                )}

                {!mobileUpdate && (
                    <Form className="col-12 col-sm-6 m-auto">
                        <Form.Group className="mb-3" controlId="formFirstName">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                                placeholder="First Name"
                                value={updateProfile.name}
                                name="name"
                                onChange={handleInputValue}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formMobileNumber">
                            <Form.Label>Mobile Number</Form.Label>
                            <Form.Control
                                placeholder="Mobile Number"
                                value={updateProfile.mobile}
                                name="mobile"
                                onChange={handleInputValue}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formEmail">
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Email Address"
                                value={updateProfile.email}
                                name="email"
                                onChange={handleInputValue}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formDOB">
                            <Form.Label>Date of Birth</Form.Label>
                            <Form.Control
                                type="date"
                                placeholder="Date of birth"
                                value={updateProfile.dob}
                                name="dob"
                                onChange={handleInputValue}
                            />
                        </Form.Group>

                        <Button
                            variant="primary"
                            className="d-block m-auto"
                            onClick={handleProfileSubmit}
                            disabled={loading}  // Disable button while loading
                        >
                            {loading ? "Updating..." : "Update Profile"}
                        </Button>
                    </Form>
                )}

                {mobileUpdate && (
                    <Form className="col-6 m-auto">
                        <Form.Group className="mb-3" controlId="formOTP">
                            <Form.Label>Enter OTP</Form.Label>
                            <Form.Control
                                placeholder="Enter OTP"
                                name="otp"
                                onChange={(e) => setOTP(e.target.value)}
                            />
                        </Form.Group>

                        <Button
                            variant="primary"
                            className="d-block m-auto"
                            onClick={handleVerifySubmit}
                            disabled={loading}  // Disable button while loading
                        >
                            {loading ? "Verifying..." : "Verify"}
                        </Button>
                    </Form>
                )}
            </Container>
        </div>
    );
}

export default MyProfile;
