import {
fetchShippingAddress,
        clearState,
        deleteShippingAddress,
        } from "../../features/profileSlice";
import GoogleMapModal from "../../common/liveGmap";

import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import {
Container,
        Form,
        Button,
        Row,
        Alert,
        Card,
        Col,
        Modal,
        } from "react-bootstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../static/css/App.css";
library.add(fas, fab, far);

function ShippingAddress() {
    const dispatch = useDispatch();
    const {shippingAddress, error, updateState, success} = useSelector(
            (state) => state.profile
    );
    useEffect(() => {
        fetchAddress();
    }, [dispatch]);

    const addrs = JSON.parse(localStorage.getItem("selectedAddress"))?.address;
    const [address, setAddress] = useState(null);
    const [addressModalShow, setAddressModalShow] = useState(false);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [deleteId, setDeleteId] = useState(0);

    const fetchAddress = () => {
        dispatch(clearState());
        dispatch(fetchShippingAddress());
    }; 
    const handleAddressEdit = (addr) => {
        if (addr) {
            setIsEdit(true);
            setAddress(addr);
        }        
        setAddressModalShow(true);
    };

    const handleAddressDelete = (addrId) => {
        setDeleteModalShow(true);
        setDeleteId(addrId);
    };
    const confirmDelete = () => {
        setDeleteModalShow(false);
        dispatch(deleteShippingAddress(deleteId));
    };

    useEffect(() => {
        if (success) {
            fetchAddress();
            setIsEdit(false);
        }
    }, [success]);

    return (
            <div>
                <Container fluid className="">
                    <Row>
                        <Row>
                            <Button
                                className="col-6 col-sm-3 ms-auto mb-3"
                                onClick={() => handleAddressEdit(null)}
                                >
                                <FontAwesomeIcon icon="fa-solid fa-plus" /> Add Address
                            </Button>
                        </Row>
                        {shippingAddress &&
                                    shippingAddress.map((addr, index) => (
                                        <Col
                                            sm={4}
                                            className={`my-3 ${addrs == addr.id ? "df-addr" : ""}`}
                                            key={index}
                                            >
                                        <Card className="h-100">
                                        <Card.Body>
                                            <div className="position-absolute end-0 pe-3 ">
                                                <FontAwesomeIcon
                                                    className="addr-type-icon align-item-center"
                                                    icon={
                                                                    addr.addressTypeId == "2"
                                                                            ? "fa-building"
                                                                            : addr.addressTypeId == "3"
                                                                            ? "fa-map"
                                                                            : "fa-home"
                                                    }
                                                    />
                                            </div>
                            
                                            <Card.Text>
                                                {addr.defaultStatus == "1" && (
                                                                        <b className="text-danger">
                                                                            Default address:
                                                                            <br />
                                                                        </b>
                                                                        )}
                                                {addr.defaultStatus == "0" && (
                                                                        <br/>
                                                                        )}
                            
                                                <strong className="mb-1 float-start w-100">{addr.name}</strong>
                                                <br />
                                                <p>
                                                    {addr.address}
                                                    <br />
                                                    {addr.landmark}
                                                    <br />
                                                    {addr.area}
                                                    <br />
                                                    {addr.mobile}
                                                </p>
                                            </Card.Text>
                                        </Card.Body>
                                        <Card.Footer className="text-end pt-0 border-top-0 bg-transparent">
                                            <Card.Link
                                                className="text-decoration-none color-primary cursor-pointer"
                                                onClick={() => handleAddressEdit(addr)}
                                                >
                                                <FontAwesomeIcon icon="fa-solid fa-edit" /> Edit
                                            </Card.Link>
                                            <Card.Link
                                                className="text-decoration-none color-primary cursor-pointer"
                                                onClick={() => handleAddressDelete(addr.id)}
                                                >
                                                <FontAwesomeIcon icon="fa-solid fa-trash-can" /> Delete
                                            </Card.Link>
                                        </Card.Footer>
                                        </Card>
                                        </Col>
                                                ))}
                    </Row>
                </Container>
                <GoogleMapModal
                    showModal={addressModalShow}
                    handleCloseModal={() => {
                            setAddressModalShow(false);
                            setIsEdit(false);
                            fetchAddress();
                        }}
                    edit={isEdit}
                    editAddress={address}
                    profile={true}
                    />
            
                <Modal
                    show={deleteModalShow}
                    onHide={() => setDeleteModalShow(false)}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="text-center"
                    >
                    <Modal.Header className="mx-auto">
                        <Modal.Title id="contained-modal-title-vcenter text-danger">
                            Alert
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h4>Are you Sure</h4>
                        <p>You want to delete?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => confirmDelete()}>
                            Delete
                        </Button>
                        <Button
                            className="bg-secondary border-secondary"
                            onClick={() => setDeleteModalShow(false)}
                            >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            );
}
export default ShippingAddress;
