import { useDispatch, useSelector } from "react-redux";
import {
fetchProduct,
        changeStateTrue,
        changeStateFalse,
        changeLoading,
        fetchProductFilters,
        clearProduct,
        } from "../features/productSlice";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ProductCard from "../components/product/product";
import CatlogPriceFilter from "../components/priceSlider/price-slider";
import META from '../common/meta';
import {
Container,
        Row,
        Col,
        Form,
        Breadcrumb,
        Dropdown,
        } from "react-bootstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../static/css/App.css";
        library.add(fas, fab, far);
        function Product() {
        const { slug } = useParams();
                const [isLoading, setIsLoading] = useState(false);
                const [priceFilter, setPriceFilter] = useState(null);
                const [productSlug, setProductSlug] = useState(null);
                const [brandFilter, setBrandFilter] = useState([]);
                const [sorting, setSorting] = useState(null);
                const [order, setOrder] = useState(null);
                const [offer, setOffer] = useState(null);
                //                const[categoryId, setCategoryId] = useState(null);
                const dispatch = useDispatch();
                const {
                productList,
                        error,
                        response,
                        completed,
                        breadCrumb,
                        bannerImage,
                        filterCategories,
                        filterBrands,
                        minPrice,
                        maxPrice,
                } = useSelector((state) => state.product);
                const fetchData = () => {
        setIsLoading(true);
                if (productSlug) {
        dispatch(
                fetchProduct({
                categoryId: productSlug,
                        price: priceFilter,
                        brand: brandFilter.join(","),
                        order: order,
                        sort: sorting,
                        offer:offer,
                })
                );
        }
        };
                const handleBrandFilter = (e) => {
        let brandId = e.target.value;
                if (brandFilter.indexOf(brandId) >= 0) {
        setBrandFilter(brandFilter.filter((bf) => bf !== brandId));
        } else {
        setBrandFilter([...brandFilter, brandId]);
        }
        };
                console.log(bannerImage, 'bannerImage');
                useEffect(() => {
                setProductSlug(slug);
                        dispatch(fetchProductFilters({ categoryId: slug }));
                        console.log(bannerImage, 'bannerImage');
                        window.scrollTo(0, 0);
                        setBrandFilter([]);
                }, [slug]);
                useEffect(() => {
                dispatch(changeLoading());
                        fetchData();
                        dispatch(changeStateTrue());
                }, [productSlug]);
                useEffect(() => {
                const handleScroll = () => {
                const { scrollTop, clientHeight, scrollHeight } =
                        document.documentElement;
                        if (scrollTop + clientHeight >= scrollHeight - 20 && !isLoading) {
                fetchData();
                        dispatch(changeStateTrue());
                }
                };
                        window.addEventListener("scroll", handleScroll);
                        return () => {
                window.removeEventListener("scroll", handleScroll);
                };
                }, [slug, isLoading]);
                useEffect(() => {
                if (completed) {
                setIsLoading(false);
                }
                }, [completed]);
                useEffect(() => {
                if (priceFilter){
                dispatch(clearProduct());
                        fetchData();
                        dispatch(changeStateTrue());
                }
                }, [priceFilter]);
                useEffect(() => {
                if (brandFilter.length > 0){
                dispatch(clearProduct());
                        fetchData();
                        dispatch(changeStateTrue());
                }
                }, [brandFilter]);
                const handleSortingFilter = (e) => {
        let value = e.target.value;
                setSorting("price");
                setOffer(null);
                if (value == 1) {
        setOrder("low");
        } else if (value == 2) {
        setOrder("high");
        } else if (value == 3) {
        setOrder("default");
                setOffer(1);
        } else if (value == 4) {
        setSorting("name");
                setOrder("asc");
        } else if (value == 5) {
        setSorting("name");
                setOrder("desc");
        } else {
        setOrder("default");
        }
        };
                useEffect(() => {
                if (order) {
                dispatch(clearProduct());
                        fetchData();
                        dispatch(changeStateTrue());
                }
                }, [order]);
                const defaultBnrImg = process.env.REACT_APP_IMAGE_URL + '/uploads/categories/df-banner.png';
                return (
                        <div>
                <META slug={productSlug} type="categories"/>
    <Container fluid="xl" className="px-md-5 px-3 px-xl-3">
        <Row>
            {breadCrumb && (
                                <Breadcrumb>
                <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                {breadCrumb.categories && (
                                        <Breadcrumb.Item
                    {...(breadCrumb.categories.categories
                                                ? { href: breadCrumb.categories.slug }
                                        : { active: true })}
                    >
                    {breadCrumb.categories.name}
                </Breadcrumb.Item>
                                        )}
                {breadCrumb.categories?.categories && (
                                <Breadcrumb.Item
                    {...(breadCrumb.categories.categories.categories
                                        ? { href: breadCrumb.categories.categories.slug }
                                : { active: true })}
                    >
                    {breadCrumb.categories.categories.name}
                </Breadcrumb.Item>
                                )}
                {breadCrumb.categories?.categories?.categories && (
                                <Breadcrumb.Item active>
                    {breadCrumb.categories.categories.categories.name}
                </Breadcrumb.Item>
                                )}
            </Breadcrumb>
                                )}
        </Row>
        <Row>
            <img src={bannerImage && bannerImage !== '' ? process.env.REACT_APP_IMAGE_URL + '/uploads/' + bannerImage : defaultBnrImg} alt="Category banner" />
        </Row>
        <Row className="my-4">
            <Col md="3">
            {breadCrumb && (
                        <h1 className="fs-4">
                {breadCrumb.categories.categories?.categories?.name}
                {breadCrumb.categories.categories &&
                        !breadCrumb.categories.categories.categories && (
                                <div>{breadCrumb.categories.categories.name}</div>
                                )}
                {breadCrumb.categories && !breadCrumb.categories.categories && (
                        <div>{breadCrumb.categories.name}</div>
                        )}
            </h1>
                        )}
            </Col>
            <Col md="4">
            <label className="float-start" style={{ paddingLeft: "5%" }}>
                Price Range
            </label>
            {minPrice && maxPrice && (
                        <CatlogPriceFilter
                min={minPrice}
                max={maxPrice}
                setPriceFilter={setPriceFilter}
                />
                        )}
            </Col>
            <Col md="5" className="text-sm-end text-center">
            <Dropdown className="d-inline mx-2" autoClose="outside">
                <Dropdown.Toggle
                    className="product-filters text-white"
                    id="dropdown-autoclose-outside"
                    >
                    All Brands
                </Dropdown.Toggle>

                <Dropdown.Menu className="filter-brand-section">
                    {filterBrands &&
                        filterBrands.length > 0 &&
                        filterBrands.map((fb, index) => (
                                <Dropdown.Item as="button" key={index}>
                        <Form.Check
                            type="checkbox"
                            id={`d-checkbox-${index}`}
                            label={fb.brandName}
                            value={fb.brandID}
                            onChange={handleBrandFilter}
                            />
                    </Dropdown.Item>
                                ))}
                </Dropdown.Menu>
            </Dropdown>

            <Dropdown className="d-inline mx-2" autoClose="true">
                <Dropdown.Toggle
                    
                    className="product-filters  text-white"
                    id="dropdown-autoclose-inside"
                    >
                    Default Sorting
                </Dropdown.Toggle>

                <Dropdown.Menu  align="end">
                    <Dropdown.Item as="button" key="sort-0">
                        <Form.Check
                            type="radio"
                            id="sorting-checkbox-0"
                            label="Default Sorting"
                            value="0"
                            name="sorting"
                            onChange={handleSortingFilter}
                            />
                    </Dropdown.Item>
                    <Dropdown.Item as="button" key="sort-1">
                        <Form.Check
                            type="radio"
                            id="sorting-checkbox-1"
                            label="Price Low"
                            value="1"
                            name="sorting"
                            onChange={handleSortingFilter}
                            />
                    </Dropdown.Item>
                    <Dropdown.Item as="button" key="sort-2">
                        <Form.Check
                            type="radio"
                            id="sorting-checkbox-2"
                            label="Price High"
                            value="2"
                            name="sorting"
                            onChange={handleSortingFilter}
                            />
                    </Dropdown.Item>
                    <Dropdown.Item as="button" key="sort-3">
                        <Form.Check
                            type="radio"
                            id="sorting-checkbox-3"
                            label="Offers Only"
                            value="3"
                            name="sorting"
                            onChange={handleSortingFilter}
                            />
                    </Dropdown.Item>
                    <Dropdown.Item as="button" key="sort-4">
                        <Form.Check
                            type="radio"
                            id="sorting-checkbox-4"
                            label="Name Asc"
                            value="4"
                            name="sorting"
                            onChange={handleSortingFilter}
                            />
                    </Dropdown.Item>
                    <Dropdown.Item as="button" key="sort-5">
                        <Form.Check
                            type="radio"
                            id="sorting-checkbox-5"
                            label="Name Desc"
                            value="5"
                            name="sorting"
                            onChange={handleSortingFilter}
                            />
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            </Col>
        </Row>
    </Container>
    <Container fluid="xl" className="px-md-5 px-3 px-xl-3">
        <Row className="my-5">
            {productList &&
                        productList.map((product, index) =>
                                product.variations.length > 0 ? (
                                        <ProductCard product={product} index={index} />
                                        ) : (
                                ""
                                )
                                )}
        </Row>
    </Container>
</div>
                        );
                }

export default Product;
        {
        /*
         {breadCrumb != null && <Breadcrumb>
         <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
         {breadCrumb.categories && <Breadcrumb.Item {...(breadCrumb.categories.categories? {href:breadCrumb.categories.slug}:{active:true})}>{breadCrumb.categories.name}</Breadcrumb.Item>}
         {breadCrumb.categories?.categories && <Breadcrumb.Item {...(breadCrumb.categories.categories.categories? {href:breadCrumb.categories.categories.slug}:{active:true})}>{breadCrumb.categories.categories.name}</Breadcrumb.Item>}
         {breadCrumb.categories?.categories?.categories && <Breadcrumb.Item active>{breadCrumb.categories.categories.categories.name}</Breadcrumb.Item>}
         </Breadcrumb>}
         */
        }

{
/*<Dropdown className="d-inline mx-2" autoClose="outside">
 <Dropdown.Toggle className="product-filters  text-white" id="dropdown-autoclose-outside">
 Categories
 </Dropdown.Toggle>
 
 <Dropdown.Menu>
 {filterCategories && filterCategories.length > 0 && filterCategories.map((fc, index) => (
 <Dropdown.Item as="button" key={index}>
 <Form.Check
 type="checkbox"
 id={`d-procheckbox-${index}`}
 label={fc.categoryName}
 value={fc.categoryID}
 />
 </Dropdown.Item>
 ))}
 </Dropdown.Menu>
 </Dropdown>*/
}

{
/* 
 <Container fluid className="previous-purchase py-4"> 
 
 <Container>
 <Row>
 <h2 className="fs-4 py-3 text-white">Your previous purchase in this category</h2>
 <OwlCarousel className='owl-theme' autoplay margin={10} items={4} dots={false}>
 {productList && productList.map((product, index) => (
 product.variations.length > 0 && index <=10 ?
 <ProductCard product={product} type="owl"/>:""
 ))}                                                                 
 </OwlCarousel> 
 </Row>
 </Container> 
 
 </Container>
 */
}

{
/*  <Row>
 <Col lg="7">
 <Image src=
 {categoryList} fluid style={{height: "346px"}}/>
 </Col>
 <Col lg="5">
 <Image src={categoryList1} fluid  className="my-2"/>
 <Image src={categoryList2} fluid  className="my-2"/>
 </Col>
 </Row>
 * */
}
