import { useDispatch, useSelector } from "react-redux";
import {
addCart,
        fetchCart,
        changeStateTrue,
        changeStateFalse,
        addToCart,
        removeFromCart,
        } from "../../features/cartSlice";
import { useState, useEffect } from "react";
import {
Container,
        Row,
        Col,
        Image,
        Breadcrumb,
        Table,
        Card,
        ListGroup,
        Tab,
        Tabs,
        Nav,
        Button,
        Badge,
        } from "react-bootstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../static/css/App.css";
//import { useGetCartQuery, useAddCartMutation } from "../features/productApi";
library.add(fas, fab, far);
function CartDetails( { cartAmounts, cartCounts, totalDiscounts, setProceedNext }) {
    const dispatch = useDispatch();
    const {cartListNew, freeProducts, cartAmount, cartCount, error, response, totalDiscount, subTotal} =
            useSelector((state) => state.cart);
    const [addCarts, setAddCart] = useState({itemId: "", qty: ""});
    const [list, setList] = useState([]);
    const handleIncrement = (item, qty, amount) => {
        dispatch(addToCart({productItemId: item, quantity: 1, price: amount}));
    };
    const handleDecrement = (item, qty, amount) => {
        //        setAddCart({itemId:item, qty:qty - 1});
        dispatch(
                removeFromCart({productItemId: item, quantity: qty, price: amount})
                );
    };
    useEffect(() => {
        cartAmounts(subTotal);
        cartCounts(cartCount);
        totalDiscounts(totalDiscount);
    }, [cartAmount]);
    useEffect(() => {
//        console.log(cartListNew);
        let stockEx = cartListNew.filter((e) => e.stock == 0);
        if (stockEx.length > 0) {
            setProceedNext(false);
        } else {
            setProceedNext(true);
        }
        setList([...cartListNew].sort((a, b) => a.stock - b.stock));
    }, [cartListNew]);
    return (
            <Table responsive className="cartTable">
                <thead>
                    <tr>
                        <th className="col-1">#</th>
                        <th className="col-5">Products on cart</th>
                        <th className="col-2 text-center">Quantity</th>
                        <th className="col-2 text-end">Net Price</th>
                        <th className="col-2 text-end">Total Price</th>
                    </tr>
                </thead>
                <tbody>
                    {cartListNew &&
                                list.map((cartProduct, index) => (
                                        <tr key={index}>
                                            <td className="align-middle">
                                                {cartProduct.productItemId && <FontAwesomeIcon
                                        icon="fa-solid fa-xmark"                                
                                        className="cursor-pointer"
                                        onClick={() =>
                                                                                        handleDecrement(
                                                                                                cartProduct.productItemId,
                                                                                                0,
                                                                                                cartProduct.price
                                                                                                )                                                                                                                                                            }
                                        style={{color: "#919191"}}
                                        />
                                    }
                                </td>
                                <td>
                                <Row className="align-items-center">
                                    <Col xs={3} md={2}>
                                    <Image
                                        src={`https://gracesupermarket.s3.ap-southeast-2.amazonaws.com/uploads/${cartProduct.image}`}
                                        rounded
                                        className="CTimg"
                                        />
                                    </Col>
                                    <Col xs={9} md={10}>
                                    <span className="mb-0 cartTxt" >
                                        {cartProduct.productName}
                                        <br />
                                        <Badge bg="success" className="">
                                            {cartProduct.badge != "" && <div>{cartProduct.badge}</div>}
                                        </Badge>
                                        {cartProduct.stock == 0 && <Badge bg="danger" >Out Of Stock</Badge>}
                                    </span>
                                    </Col>
                                </Row>
                                </td>
                                <td>
                                    <div className="input-group justify-content-center Cvarient mt-1">
                                        {cartProduct.productItemId &&
                                                                        <div className="input-group-prepend cursor-pointer ">
                                                                            <span
                                                                                className="input-group-text"
                                                                                onClick={() =>
                                                                                                handleDecrement(
                                                                                                        cartProduct.productItemId,
                                                                                                        cartProduct.totalQuantity,
                                                                                                        cartProduct.price
                                                                                                        )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                >
                                                                                -
                                                                            </span>
                                                                        </div>}
                                        <input
                                            type="text"
                                            className="form-control p-1 text-center"
                                            value={cartProduct.totalQuantity}
                                            disabled
                                            aria-label="Amount (to the nearest dollar)"
                                            />
                                        {cartProduct.productItemId && <div className="input-group-append cursor-pointer">
                                            <span
                                                className="input-group-text"
                                                onClick={() =>
                                                                                        handleIncrement(
                                                                                                cartProduct.productItemId,
                                                                                                cartProduct.totalQuantity,
                                                                                                cartProduct.price
                                                                                                )
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                >
                                                +
                                            </span>
                                        </div>
                                        }
                                    </div>
                                </td>
                                <td className="text-end">
                                    {(cartProduct.mrpPrice != cartProduct.price) && (
                                                                        <div className="w-100 cartMrpPrice mt-2 text-secondary text-decoration-line-through">
                                                                    
                                                                    
                                                                            <FontAwesomeIcon
                                                                                icon="fa-solid fa-indian-rupee-sign"
                                                                                size="sm"
                                                                                />
                                                                            &nbsp;{cartProduct.mrpPrice}
                                                                        </div>
                                                        )}
                                    <div className="w-100 cartPrice mt-2">
                                        <FontAwesomeIcon
                                            icon="fa-solid fa-indian-rupee-sign"
                                            size="sm"
                                            />
                                        &nbsp;{cartProduct.price}
                                    </div>
                                </td>
                                <td className="text-end">
                                    <div className="w-100 cartPrice mt-2">
                                        <FontAwesomeIcon
                                            icon="fa-solid fa-indian-rupee-sign"
                                            size="sm"
                                            />
                                        &nbsp;
                                        {(cartProduct.price * cartProduct.totalQuantity).toFixed(2)}
                                    </div>
                                </td>
                                </tr>
                                    ))}
            </tbody>
            </Table>
            );
}

export default CartDetails;
