import { fetchOrderDetails, clearState } from "../../features/profileSlice";
import { selectCurrentToken } from "../../features/auth/authSlice";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Modal, Form, Row, Col, Image, Button, Alert, Table, Card, ListGroup, Badge } from 'react-bootstrap';
import moment from 'moment';

function OrderDetails() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const [cancelModalShow, setCancelModalShow] = useState(false);
    const [reModalShow, setReModalShow] = useState(false);
    const [cancelReason, setCancelReason] = useState('');
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null); // Success message state

    const { orderDetails, orderStatus } = useSelector(state => state.profile);
    const token = useSelector(selectCurrentToken);

    // Fetch order details on mount and reset state on unmount
    useEffect(() => {
        dispatch(clearState());
        dispatch(fetchOrderDetails(id));
        setError(null);
        setSuccessMessage(null); // Reset success message on component mount
    }, [dispatch, id]);

    // Handle Cancel Order
    const handleCancelSubmit = async () => {
        if (!cancelReason.trim()) {
            alert("Cancel Reason Required");
            return;
        }

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_ORDER_BASE_URL}/orders/cancel`,
                { orderId: id, reason: cancelReason },
                { headers: { Authorization: token } }
            );

            if (response.data.status === "FAIL") {
                setError(response.data.message);
            } else {
                setSuccessMessage("Order cancelled successfully.");
                dispatch(fetchOrderDetails(id)); // Re-fetch order after cancellation
                setCancelModalShow(false);
            }
        } catch (err) {
            setError("Something went wrong. Please try again later.");
        }
    };

    // Handle Re-order
    const handleReOrderSubmit = async () => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_ORDER_BASE_URL}/orders/reorder`,
                { orderId: id },
                { headers: { Authorization: token } }
            );

            if (response.data.status === "FAIL") {
                setError(response.data.message);
            } else {
                setSuccessMessage("Reorder successful. Redirecting to your cart...");
                setTimeout(() => {
                    navigate('/cart'); // Redirect to cart page after success
                }, 2000);
                setReModalShow(false);
            }
        } catch (err) {
            setError("Something went wrong. Please try again later.");
        }
    };

    // Render order details if available
    const renderOrderDetails = () => {
        if (!orderDetails) return null;
        return (
            <Container >
                <Row className="my-3 my-sm-5">
                    <h3>Order Status: <span className="text-warning">{orderStatus[orderDetails.status]}</span></h3>
                </Row>

                <Row className="text-end">
                    <Button variant="warning" className="w-auto me-2 ms-auto" onClick={() => setReModalShow(true)}>Re Order</Button>
                    {orderDetails.status < 3 && (
                        <Button variant="danger" className="w-auto" onClick={() => setCancelModalShow(true)}>Cancel Order</Button>
                    )}
                </Row>

                <Row className="bg-light py-3 py-sm-5">
                    {renderOrderInfo()}
                    {renderAddressInfo()}
                    {renderPaymentInfo()}
                    {renderHelpInfo()}
                </Row>

                <Row className="mt-4">
                    {renderOrderProducts()}
                    {renderOrderSummary()}
                </Row>
            </Container>
        );
    };

    // Helper function to render Order Information
    const renderOrderInfo = () => (
        <Col sm={3}>
            <h5>Order Details</h5>
            <p>Your Order Id: <span className="text-danger">{orderDetails.showId}</span></p>
            <p>Placed On: {moment(orderDetails.postedDate).format("DD/MM/YYYY")}</p>
            <p>Delivery Date: {moment(orderDetails.deliveryDate).format("DD/MM/YYYY")}</p>
            {orderDetails.deliveryFromTime && <p> Delivery Time: {moment(orderDetails.deliveryFromTime).format("hh:mm a")} - {moment(orderDetails.deliveryToTime).format("hh:mm a")}</p>}
        </Col>
    );

    // Helper function to render Address Information
    const renderAddressInfo = () => (
        <Col sm={3}>
            <h5>Address</h5>
            <p><span className="text-danger">{orderDetails.shippingAddress.name}</span></p>
            <p>{orderDetails.shippingAddress.address}</p>
            <p>{orderDetails.shippingAddress.landmark}</p>
            <p>{orderDetails.shippingAddress.area}, {orderDetails.shippingAddress.city}-{orderDetails.shippingAddress.pincode}</p>
            <p>{orderDetails.shippingAddress.state}</p>
        </Col>
    );

    // Helper function to render Payment Information
    const renderPaymentInfo = () => (
        <Col sm={3}>
            <h5>Payment Method</h5>
            <p><span className="text-danger">{parseInt(orderDetails.paymentType) === 1 ? "Cash On Delivery" : parseInt(orderDetails.paymentType) === 2 ? "Card on Delivery" : " Not provided "}</span></p>
        </Col>
    );
    const renderHelpInfo = () => (
        <Col sm={3}>
            <h5>Need Help?</h5>
            <p className="mb-1">We are happy to assist you</p>
            <p className="mb-1"><span className="text-danger">+91 9876543210</span></p>
        </Col>
    );

    // Helper function to render Order Products
    const renderOrderProducts = () => (
        <Col xs="12" sm="8">
            <Table responsive>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Products</th>
                        <th className="text-center">Quantity</th>
                        <th className="text-end">Net Price</th>
                        <th className="text-end">Total Price</th>
                    </tr>
                </thead>
                <tbody>
                    {orderDetails.products.map((cartProduct, index) => (
                        <tr key={index}>
                            {cartProduct.isFreeProduct === 0 && <td>{index + 1}</td>}
                            <td>
                                <Row className="align-items-center">
                                    <Col xs={6} md={3}>
                                        <Image src={`https://gracesupermarket.s3.ap-southeast-2.amazonaws.com/uploads/${cartProduct.productImage}`} rounded style={{ height: "45px" }} />
                                    </Col>
                                    <Col xs={6} md={9}>
                                        <span>{cartProduct.productName}</span><br />
                                        <Badge bg="success">
                                            {cartProduct.offerType === 3 && cartProduct.discountType === 0 && `${cartProduct.discountValue}%`}
                                            {cartProduct.offerType === 3 && cartProduct.discountType === 1 && `₹${cartProduct.discountValue}`}
                                            {cartProduct.offerType === 4 && !cartProduct.isFreeProduct && "BOGO"}
                                            {cartProduct.offerType === 5 && !cartProduct.isFreeProduct && "Free Offer"}
                                        </Badge>
                                    </Col>
                                </Row>
                            </td>
                            <td className="text-center">{cartProduct.productQty}</td>
                            <td className="text-end">₹{cartProduct.salePrice}</td>
                            <td className="text-end">₹{cartProduct.proTotalPrice.toFixed(2)}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Col>
    );

    // Helper function to render Order Summary
    const renderOrderSummary = () => (
        <Col sm={4}>
            <Card bg="light">
                <Card.Body className="p-0 pt-3">
                    <Card.Title className="text-center">Order Summary</Card.Title>
                    <ListGroup className="text-start">
                        <ListGroup.Item>Sub Total <span className="float-end">{(parseFloat(orderDetails.subTotal) + parseFloat(orderDetails.oDiscountAmount)).toFixed(2)}</span></ListGroup.Item>
                        <ListGroup.Item>Total Discount <span className="float-end">{orderDetails.oDiscountAmount}</span></ListGroup.Item>
                        <ListGroup.Item>Delivery Charges <span className="float-end">Free</span></ListGroup.Item>
                    </ListGroup>
                </Card.Body>
                <Card.Footer>
                    <small><strong>Total Amount</strong><span className="float-end">{orderDetails.totalAmount}</span></small>
                </Card.Footer>
            </Card>
        </Col>
    );

    return (
        <div>
            {error && <Alert variant="danger" dismissible className="text-center">{error}</Alert>}
            {successMessage && <Alert variant="success" dismissible className="text-center">{successMessage}</Alert>}
            {renderOrderDetails()}

            {/* Cancel Order Modal */}
            <Modal show={cancelModalShow} onHide={() => setCancelModalShow(false)} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Cancel Order</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Reason</h4>
                    <Form.Control as="textarea" onChange={(e) => setCancelReason(e.target.value)} />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleCancelSubmit}>Submit</Button>
                    <Button onClick={() => setCancelModalShow(false)}>Close</Button>
                </Modal.Footer>
            </Modal>

            {/* Re-order Modal */}
            <Modal show={reModalShow} onHide={() => setReModalShow(false)} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Re Order</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4 className="text-center">Are you sure you want to re-order?</h4>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleReOrderSubmit}>Yes</Button>
                    <Button onClick={() => setReModalShow(false)}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default OrderDetails;
