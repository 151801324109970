import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const searchState = {
    //    updateState: false,
    //    loading: false,
    sproductList: [],
    offset: 0,
    error: "",
    response: "",
    completed:false
};
export const fetchSearchProductList = createAsyncThunk(
        "product/fetchSearchProductList",
        async (data, { getState }) => {
    const product = getState().search;
    const user = getState().auth.user;
    const branch = JSON.parse(localStorage.getItem("selectedBranch"));
    const response = await axios.get(
            process.env.REACT_APP_PRODUCT_BASE_URL +
            `/product/search?limit=20&offset=${product.offset}&keyword=${data.search}&branchId=${branch.id}`,
            {headers: {tempusername: user.name}}
    );
    return response.data;
}
);

const searchSlice = createSlice({
    name: "search",
    initialState: searchState,
    reducers: {
        changeStateTrue: (state) => {
            state.completed = false;
            state.offset = state.offset + 20;
        },
        changeStateFalse: (state) => {
            state.updateState = false;
        },
        clearResponse: (state) => {
            state.response = "";
        },
        changeLoading: (state) => {
            state.sproductList = [];
            state.offset = 0;
            state.error = "";
            state.response = "";
            state.completed=false;
        },
        clearSearchProduct: (state) => {
            state.searchProductList = [];
        },
        clearProduct: (state) => {
            state.sproductList = [];
            state.offset = 0;
        },
    },
    extraReducers: (builder) => {
        //        builder
        //                .addCase(addEmployee.pending, (state) => {
        //                    state.loading = true;
        //                })
        //                .addCase(addEmployee.fulfilled, (state, action) => {
        //                    state.loading = false;
        //                    state.employeeList.push(action.payload);
        //                    state.response = "add";
        //                })
        //                .addCase(addEmployee.rejected, (state, action) => {
        //                    state.loading = false;
        //                    state.error = action.error.message;
        //                });

        builder
                .addCase(fetchSearchProductList.fulfilled, (state, action) => {
                                       console.log(action);
                    if (action.payload.status == "SUCCESS") {
                        state.sproductList = [...state.sproductList, ...action.payload.data];
                        state.completed = true;
                    }
                })
                .addCase(fetchSearchProductList.rejected, (state, action) => {
                    state.error = action.error.message;
                });               
    },
});

export default searchSlice.reducer;
export const {
    changeStateTrue,
    changeStateFalse,
    clearResponse,
    changeLoading,
    clearProduct,
    clearSearchProduct
} = searchSlice.actions;
