import MyProfile from "../components/profile/MyProfile";
import MyOrders from "../components/profile/MyOrders";
import ChangePassword from "../components/profile/ChangePassword";
import MonthlyList from "../components/profile/MonthlyList";
import ShippingAddress from "../components/profile/ShippingAddress";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
selectCurrentUser,
        selectCurrentToken,
        logOut,
        } from "../features/auth/authSlice";
import { clearState } from "../features/cartSlice";
import {
Container,
        Row,
        Col,
        Image,
        Breadcrumb,
        Table,
        Card,
        ListGroup,
        Tab,
        Tabs,
        Nav,
        Button,
        Toast,
        ToastContainer,
        Alert,
        } from "react-bootstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../static/css/App.css";
library.add(fas, fab, far);
function Profile() {
    const dispatch = useDispatch();
    const {page} = useParams();
    const navigate = useNavigate();
    const [currentTab, setCurrentTab] = useState("profile");
    const logouts = async (e) => {
        dispatch(logOut());
        dispatch(clearState());
        navigate("/");
    };
    const user = useSelector(selectCurrentUser);
    const token = useSelector(selectCurrentToken);
    if (!token) {
        navigate("/");
    }
    useEffect(() => {
        if (!token) {
            navigate("/");
        } else {
            if (page) {
                setCurrentTab(page);
            } else {
                setCurrentTab("profile");
            }
        }
        window.scrollTo(0, 0);
    }, [page]);
    return (
            <div>
                <Container  fluid="xl"  className="px-md-5 px-3 px-xl-3">
                    <Row>
                        <Card className="col-sm-3 col-12 px-0 h-100">
                        <ListGroup variant="flush">
                            <ListGroup.Item>
                                <Row
                                    className={
                                        "cursor-pointer py-1 " +
                                                (currentTab == "profile" ? "active-c" : "")
                                    }
                                    onClick={(e) => setCurrentTab("profile")}
                                    >
                                    <Col className="col-2 profile-section-icon">
                                    <FontAwesomeIcon icon="fa-solid fa-circle-user" size="xl" />
                                    </Col>
                                    <Col className="col-10">
                                    My Profile <br />
                                    <span className="text-secondary ">
                                        <small>Manage your personal details</small>
                                    </span>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Row
                                    className={
                                        "cursor-pointer py-1 " +
                                                (currentTab == "myorders" ? "active-c" : "")
                                    }
                                    onClick={(e) => setCurrentTab("myorders")}
                                    >
                                    <Col className="col-2 profile-section-icon">
                                    <FontAwesomeIcon
                                        icon="fa-solid fa-bag-shopping"
                                        size="xl"
                                        />
                                    </Col>
                                    <Col className="col-10">
                                    My Orders
                                    <br />
                                    <span className="text-secondary">
                                        <small>Manage your orders</small>
                                    </span>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Row
                                    className={
                                        "cursor-pointer  py-1 " +
                                                (currentTab == "address" ? "active-c" : "")
                                    }
                                    onClick={(e) => setCurrentTab("address")}
                                    >
                                    <Col className="col-2 profile-section-icon">
                                    <FontAwesomeIcon
                                        icon="fa-solid fa-address-card"
                                        size="xl"
                                        />
                                    </Col>
                                    <Col className="col-10">
                                    Manage Address
                                    <br />
                                    <span className="text-secondary">
                                        <small>Manage your shipping address</small>
                                    </span>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Row
                                    className={
                                        "cursor-pointer  py-1 " +
                                                (currentTab == "password" ? "active-c" : "")
                                    }
                                    onClick={(e) => setCurrentTab("password")}
                                    >
                                    <Col className="col-2 profile-section-icon">
                                    <FontAwesomeIcon icon="fa-solid fa-key" size="xl" />
                                    </Col>
                                    <Col className="col-10" >
                                    Change Password
                                    <br />
                                    <span className="text-secondary">
                                        <small>Manage your password</small>
                                    </span>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
            
                            <ListGroup.Item>
                                <Row
                                    className={
                                        "cursor-pointer  py-1 " +
                                                (currentTab == "monthly-list" ? "active-c" : "")
                                    }
                                    onClick={(e) => setCurrentTab("monthly-list")}
                                    >
                                    <Col className="col-2 profile-section-icon">
                                    <FontAwesomeIcon
                                        icon="fa-solid fa-basket-shopping"
                                        size="xl"
                                        />
                                    </Col>
                                    <Col className="col-10" >
                                    Monthly List
                                    <br />
                                    <span className="text-secondary">
                                        <small>Manage your monthly lists</small>
                                    </span>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        </ListGroup>
                        <Card.Footer
                            className="py-3 text-theme-red fs-5 fw-bold cursor-pointer"
                            onClick={logouts}
                            >
                            <FontAwesomeIcon
                                icon="fa-solid fa-arrow-right-from-bracket"
                                size="1x"
                                />
                            &nbsp;&nbsp;Logout
                        </Card.Footer>
                        </Card>
                        <div className="col-sm-9 col-12 float-end pe-0 ps-sm-4 ps-0">
                            <Card className="border-0">
                            <Card.Body className="p-0 p-sm-2 mt-4 mt-sm-0">
                                {currentTab == "profile" && <MyProfile />}
                                {currentTab == "address" && <ShippingAddress />}
                                {currentTab == "myorders" && <MyOrders />}
                                {currentTab == "password" && <ChangePassword />}
                                {currentTab == "monthly-list" && <MonthlyList />}
                            </Card.Body>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </div>
            );
}

export default Profile;
{/* <ListGroup.Item>
                <Row
                  className={
                    "cursor-pointer  py-1 " +
                    (currentTab == "notification" ? "active-c" : "")
                  }
                  onClick={(e) => setCurrentTab("notification")}
                >
                  <Col md={2} className="profile-section-icon">
                    <FontAwesomeIcon icon="fa-solid fa-bell" size="xl" />
                  </Col>
                  <Col md={10}>
                    Notification
                    <br />
                    <span className="text-secondary">
                      <small>See latest updates</small>
                    </span>
                  </Col>
                </Row>
              </ListGroup.Item> */
}