import {React, useState, useEffect} from 'react';
import axios from "axios";
import { Helmet } from 'react-helmet';
export default function META( {slug = '', type = ''}) {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [image, setImage] = useState("");
    const [name, setName] = useState("");

    useEffect(() => {
        async function fetchSeo() {
            let addressUrl = process.env.REACT_APP_BASE_URL + "/seo?slug=" + slug + "&type=" + type;
            const response = await axios.get(addressUrl);
            if (response.data.status == "SUCCESS") {
                setTitle(response.data.data.title);
                setDescription(response.data.data.description);
                setImage(process.env.REACT_APP_BASE_URL + "" + response.data.data.image);
                setName(response.data.data.title);
            }
        }
        fetchSeo();
    }, [slug, type]);
    return (
            <Helmet>
                <title>{title}</title>
                <link rel='canonical' href={ window.location.href } />
                <meta name='description' content={description} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content={image} />
                <meta property="og:image:secure_url" content={image} />
                <meta property="og:image:type" content="image/jpeg" />
                <meta property="og:image:width" content="200" />
                <meta property="og:image:alt" content={`Image of ${title} site`} />
                <meta name="twitter:creator" content={name} />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
            </Helmet>
            )
}

